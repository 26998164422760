// DisplayAllTelephonesPage.tsx
import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  useFetchAvailablePhoneNumbersQuery,
  useReservePhoneNumberMutation,
} from '../../store/receptionistSlice';
import LoadingPage from '../LoadingWidget';
import ErrorWidget from '../error-helpers/ErrorWidget';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchReceptionistQuery } from '../../store/receptionistSlice';
import { showErrorToast, showSuccessToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';

export default function DisplayAllTelephonesPage() {
  const { data, error, isLoading } = useFetchAvailablePhoneNumbersQuery();

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const receptionistId = urlSearchParams.get('receptionistId');
  const navigate = useNavigate();
  const {
    data: receptionist,
    isLoading: isReceptionistLoading,
    error: receptionistError,
  } = useFetchReceptionistQuery({ id: receptionistId ?? '', offset: 0 });

  // State for handling the dialog visibility and selected phone number
  const [open, setOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(
    null
  );

  const [reserveNumberMutation, { isLoading: reserveNumberLoading }] =
    useReservePhoneNumberMutation();

  // Function to open the dialog
  const handleOpenDialog = (phoneNumber: string) => {
    setSelectedPhoneNumber(phoneNumber);
    setOpen(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedPhoneNumber(null);
  };

  const handleReservePhoneNumber = async () => {
    try {
      if (!selectedPhoneNumber || !receptionist) {
        return;
      }
      await reserveNumberMutation({
        agentId: receptionist.id,
        phoneNumber: selectedPhoneNumber,
      }).unwrap();
      showSuccessToast('Successfully reserved this phone number!');
      navigate(`/edit/${receptionistId}`);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorWidget error={error} />;
  }

  if (!data || data.length === 0) {
    return <ErrorWidget error="No data to display." />;
  }

  if (isReceptionistLoading) {
    return <LoadingPage />;
  }

  if (receptionistError) {
    return <ErrorWidget error={receptionistError} />;
  }

  if (!receptionist) {
    return <ErrorWidget error="No data to display." />;
  }

  return (
    <Box padding={2}>
      <Typography variant="h5" gutterBottom>
        Available Phone Numbers
      </Typography>
      <List>
        {data.map((phone) => (
          <ListItemButton
            key={phone.phoneNumber}
            onClick={() => handleOpenDialog(phone.phoneNumber)}
          >
            <ListItemText
              primary={phone.friendlyName}
              secondary={`${phone.phoneNumber} - ${phone.region}, ${phone.isoCountry}`}
            />
          </ListItemButton>
        ))}
      </List>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Reserve Phone Number</DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to reserve the phone number {selectedPhoneNumber} for
            the receptionist {receptionist.displayName}?
          </Typography>

          {reserveNumberLoading && <LoadingPage />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleReservePhoneNumber} color="primary">
            Reserve
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
