import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import {
  Intent,
  TrainingPhrase,
  useFetchIntentQuery,
  useUpdateIntentMutation,
} from '../../store/receptionistSlice';
import ErrorWidget from '../error-helpers/ErrorWidget';
import LoadingPage from '../LoadingWidget';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { showErrorToast, showSuccessToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';

export default function EditReceptionistIntent() {
  const { id: intentId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { data, isLoading, error } = useFetchIntentQuery({
    intentId: intentId ?? '',
    agentId: queryParams.get('agentId') ?? '',
  });

  const [trainingPhrases, setTrainingPhrases] = useState<TrainingPhrase[]>(
    data?.trainingPhrases ?? []
  );
  const [newPhrase, setNewPhrase] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<null | string>(null);
  const [description, setDescription] = useState<null | string | undefined>(
    null
  );

  const [updateIntent, { isLoading: updateIntentLoading }] =
    useUpdateIntentMutation();

  useEffect(() => {
    if (!isLoading && !error && data) {
      setTrainingPhrases(data.trainingPhrases);
      setDisplayName(data.displayName);
      setDescription(data.description);
    }
  }, [data, isLoading, error]);

  if (error) {
    return ErrorWidget({ error: error });
  }

  if (isLoading) {
    return LoadingPage();
  }

  if (!data) {
    return ErrorWidget({ error: 'No data to display.' });
  }

  const handleAddPhrase = () => {
    if (!newPhrase || newPhrase.trim() === '') {
      return;
    }

    const newTrainingPhrase: TrainingPhrase = {
      parts: [{ text: newPhrase, parameterId: '' }],
      id: '',
      repeatCount: 1,
    };

    setTrainingPhrases([...trainingPhrases, newTrainingPhrase]);
    setNewPhrase('');
  };

  const handleRemovePhrase = (index: number) => {
    const currentPhrases = [...trainingPhrases];
    currentPhrases.splice(index, 1);
    setTrainingPhrases(currentPhrases);
  };

  const onSaveChangesClicked = async () => {
    try {
      const updatedIntent: Intent = {
        ...data,
        displayName: displayName ?? '',
        description: description ?? data.description,
        trainingPhrases,
      };
      await updateIntent(updatedIntent).unwrap();
      showSuccessToast('Successfully updated this intent!');
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  return (
    <Box padding={'25px'}>
      <Typography variant="h5">Edit Intent</Typography>

      <Box marginTop={'25px'}>
        <TextField
          fullWidth={true}
          value={displayName}
          onChange={(e) => {
            setDisplayName(e.target.value);
          }}
          label={'Display Name'}
        />
      </Box>

      <Box marginTop={'25px'}>
        <TextField
          fullWidth={true}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          label={'Description'}
        />
      </Box>

      <Box mt={4} display="flex" flexDirection={'column'} alignItems="start">
        <Typography>Training Phrases</Typography>

        <Box display={'flex'} alignItems={'center'} width={'100%'}>
          <TextField
            label="New Training Phrase"
            value={newPhrase}
            onKeyDown={(key) => {
              if (key.key === 'Enter') {
                handleAddPhrase();
              }
            }}
            onChange={(e) => setNewPhrase(e.target.value)}
            fullWidth
            margin="normal"
          />
          <IconButton color="primary" onClick={handleAddPhrase}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>

      <List>
        {trainingPhrases.map((phrase, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemovePhrase(index)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={phrase.parts.map((parts) => parts.text).join(' ')}
            />
          </ListItem>
        ))}
      </List>

      {/* Save button (you can add functionality to save the changes) */}
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={onSaveChangesClicked}
      >
        Save Changes
      </Button>

      {updateIntentLoading && <LoadingPage />}
    </Box>
  );
}
