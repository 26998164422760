import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Card,
} from '@mui/material';
import {
  useGetSubscriptionDetailsQuery,
  useCancelSubscriptionMutation,
  useCreateSubscriptionMutation,
  useReactivateSubscriptionMutation,
} from '../../store/authApi';
import LoadingPage from '../LoadingWidget';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { format } from 'date-fns';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import { showErrorToast, showSuccessToast } from '../../helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const ProfileContent = () => {
  const stripe = useStripe();
  const elements = useElements();
  const user = useSelector((state: RootState) => state.auth.user); // Assuming user data is in auth slice
  const { data: subscriptionDetails, isLoading } =
    useGetSubscriptionDetailsQuery();
  const [cancelSubscription, { isLoading: isCanceling }] =
    useCancelSubscriptionMutation();
  const [createSubscription, { isLoading: isCreating }] =
    useCreateSubscriptionMutation();
  const [reactivateSubscription, { isLoading: isReactivatingSubscription }] =
    useReactivateSubscriptionMutation();

  const [billingCountry, setBillingCountry] = useState('United States');
  const [zip, setZip] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);

  const currentDate = new Date(); // Current date
  const navigate = useNavigate();

  // Logic to check if subscription can be reactivated
  const subscriptionCanBeReactivated =
    !user?.hasActiveSubscription &&
    user?.subscribedUntil &&
    currentDate < new Date(user?.subscribedUntil);

  const handleSubscribe = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            country: billingCountry,
            postal_code: zip,
          },
        },
      });

      if (error) {
        setErrorMessage(error.message || 'Failed to create payment method.');
        return;
      }

      if (paymentMethod) {
        await createSubscription({
          paymentMethodId: paymentMethod.id,
        }).unwrap();
        showSuccessToast(
          'Subscription created successfully! You now have PRO benefits!'
        );
        setOpenSubscribeDialog(false); // Close dialog after success
      }
    } catch (error) {
      console.error('Failed to create subscription:', error);
      showErrorToast(
        `Failed to create subscription. ${getErrorMessage(error)}`
      );
    }
  };

  const handleCancelSubscription = async () => {
    try {
      if (subscriptionCanBeReactivated) {
        await reactivateSubscription().unwrap();
        showSuccessToast('Success! Subscription reactivated.');
      } else {
        await cancelSubscription().unwrap();
        showSuccessToast('Subscription canceled successfully.');
      }
      setOpenDialog(false);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenSubscribeDialog = () => {
    setOpenSubscribeDialog(true);
  };

  const handleCloseSubscribeDialog = () => {
    setOpenSubscribeDialog(false);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!user) {
    return (
      <Typography>No user data available. Please log in again.</Typography>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="25px"
    >
      <Typography variant="h5">Profile Settings</Typography>
      {/* Editable user fields */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'15px'}
        marginTop={'15px'}
        width={isMobile ? '100%' : '50%'}
      >
        <Box
          display={'flex'}
          flexDirection={isMobile ? 'column' : 'row'}
          gap={'15px'}
        >
          <TextField
            label="First Name"
            name="firstName"
            disabled={true}
            value={user.firstName}
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Last Name"
            name="lastName"
            disabled={true}
            value={user.lastName}
            variant="outlined"
            fullWidth
          />
        </Box>

        <Box
          display={'flex'}
          flexDirection={isMobile ? 'column' : 'row'}
          gap={'15px'}
        >
          <TextField
            label="Email"
            name="email"
            disabled={true}
            value={user.email}
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Phone Number"
            name="phoneNumber"
            disabled={true}
            value={user.phoneNumber}
            variant="outlined"
            fullWidth
          />
        </Box>

        <TextField
          label="Username"
          name="username"
          disabled={true}
          value={user.username}
          variant="outlined"
          fullWidth
        />
      </Box>
      {/* Subscription Details Section */}
      <Card sx={{ maxWidth: 800, width: '100%', p: 3, mt: 4 }}>
        <Typography variant="h5" gutterBottom align="center">
          Subscription Status
        </Typography>

        {user.stripeSubscriptionId ? (
          <>
            <Typography variant="body1">
              Your subscription is{' '}
              <strong>
                {user.hasActiveSubscription
                  ? 'active'
                  : `cancelled, but you still have PRO benefits until ${format(
                      Date.now(),
                      'MMMM dd, yyyy'
                    )}`}
              </strong>
              .
            </Typography>
            {subscriptionDetails && (
              <>
                <Typography variant="body2">
                  Next billing date:{' '}
                  <strong>
                    {format(
                      new Date(subscriptionDetails.currentPeriodEnd),
                      'MMMM dd, yyyy'
                    )}
                  </strong>
                </Typography>
                <Typography variant="body2">
                  Subscription Plan: <strong>PRO</strong>
                </Typography>
              </>
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
                disabled={isCanceling || isReactivatingSubscription}
                fullWidth
              >
                {subscriptionCanBeReactivated
                  ? 'Reactivate Subscription'
                  : 'Cancel Subscription'}
              </Button>
            </Box>
            {(isCanceling || isReactivatingSubscription) && (
              <CircularProgress />
            )}

            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>
                {subscriptionCanBeReactivated
                  ? 'Reactivate Subscription'
                  : 'Confirm Subscription Cancellation'}
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {subscriptionCanBeReactivated
                    ? 'Are you sure you want to reactivate your subscription?'
                    : 'Are you sure you want to cancel your subscription? You will lose access to premium features.'}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  {subscriptionCanBeReactivated
                    ? 'Keep Cancelled'
                    : 'Keep Subscription'}
                </Button>
                <Button onClick={handleCancelSubscription} color="secondary">
                  {subscriptionCanBeReactivated
                    ? 'Reactivate'
                    : 'Cancel Subscription'}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <>
            <Typography variant="body1">
              You do not have an active subscription.
            </Typography>

            {/* Card Information for Subscription */}
            <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
              <LockOutlined sx={{ mr: 1 }} />
              <Typography variant="body1" fontWeight="bold">
                Card Information
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  padding: '10px',
                  border: '1px solid #c4c4c4',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              >
                <CardNumberElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#000',
                        '::placeholder': {
                          color: '#888',
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            {/* Expiration Date and CVC */}
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <Box
                sx={{
                  padding: '10px',
                  border: '1px solid #c4c4c4',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  flex: 1,
                }}
              >
                <CardExpiryElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#000',
                        '::placeholder': {
                          color: '#888',
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  padding: '10px',
                  border: '1px solid #c4c4c4',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  flex: 1,
                }}
              >
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#000',
                        '::placeholder': {
                          color: '#888',
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            {/* Billing Country and ZIP Code */}
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <TextField
                label="Billing Country"
                select
                fullWidth
                value={billingCountry}
                onChange={(e) => setBillingCountry(e.target.value)}
              >
                <MenuItem value="US">United States</MenuItem>
                <MenuItem value="CA">Canada</MenuItem>
                <MenuItem value="UK">United Kingdom</MenuItem>
              </TextField>
              <TextField
                label="ZIP Code"
                fullWidth
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </Box>

            {/* Terms Checkbox */}
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: 'underline', color: 'primary.main' }}
                  >
                    I agree to the Terms & Conditions
                  </Typography>
                }
              />
            </Box>

            {errorMessage && (
              <Typography color="error" gutterBottom>
                {errorMessage}
              </Typography>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenSubscribeDialog}
              disabled={!stripe || isCreating || !agreedToTerms}
              fullWidth
            >
              {isCreating ? <CircularProgress size={20} /> : 'Subscribe Now'}
            </Button>

            {/* Subscribe Confirmation Dialog */}
            <Dialog
              open={openSubscribeDialog}
              onClose={handleCloseSubscribeDialog}
            >
              <DialogTitle>Confirm Subscription</DialogTitle>
              <DialogContent>
                <Typography>
                  You are about to subscribe to PRO for $19.99 per month. Do you
                  wish to continue?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseSubscribeDialog} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleSubscribe} color="secondary">
                  Confirm Subscription
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Card>

      {/* Logout Button */}
      <Button
        variant="contained"
        onClick={() => navigate('/logout')}
        style={{ marginTop: '20px' }}
      >
        Logout
      </Button>
    </Box>
  );
};

export default ProfileContent;
