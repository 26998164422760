import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { Flow } from '../../store/flowsApi';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorWidget from '../error-helpers/ErrorWidget';
import LoadingPage from '../LoadingWidget';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { showErrorToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import {
  useDeleteFlowMutation,
  useFetchFlowsQuery,
} from '../../store/flowsApi';
import { useFetchReceptionistQuery } from '../../store/receptionistSlice';
export default function ViewReceptionistFlows() {
  const { agentId } = useParams<string>();
  const navigate = useNavigate();
  const { data, isLoading, error } = useFetchFlowsQuery(agentId ?? '');
  const { data: receptionist, isLoading: receptionistLoading } =
    useFetchReceptionistQuery({ id: agentId ?? '', offset: 0 });
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentFlow, setCurrentFlow] = useState<Flow | null>(null);
  const [deleteFlow, { isLoading: deleteFlowLoading }] =
    useDeleteFlowMutation();

  if (error) {
    return ErrorWidget({ error });
  }

  if (isLoading) {
    return LoadingPage();
  }

  if (!data) {
    return ErrorWidget({ error: 'No data to display' });
  }

  const onAddFlowClicked = () => {
    navigate(`/flows/create/${agentId}`);
  };

  const getFlowIdFromName = (flow: Flow): string => {
    const parts = flow.name?.split('/') ?? [];
    return parts.length > 0 ? parts[parts.length - 1] : '';
  };

  const onDeleteFlowClicked = async (flow: Flow) => {
    try {
      const flowId = getFlowIdFromName(flow);
      const id = agentId as string;
      await deleteFlow({ flowId, agentId: id }).unwrap();
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const onDeleteIconClicked = (flow: Flow) => {
    setDialogOpen(true);
    setCurrentFlow(flow);
  };

  const onEditFlowClicked = async (flow: Flow) => {
    navigate(`/flows/edit/${agentId}?flowId=${getFlowIdFromName(flow)}`);
  };

  return (
    <Box sx={{ padding: '25px' }}>
      {receptionistLoading && <LoadingPage />}
      {receptionist && (
        <Typography variant="h5" gutterBottom>
          {receptionist.displayName} Flows
        </Typography>
      )}

      <Paper elevation={1}>
        <List>
          {data.map((flow: Flow, index: number) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDeleteIconClicked(flow)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <IconButton onClick={() => onEditFlowClicked(flow)}>
                <EditIcon />
              </IconButton>
              <Box marginLeft={'10px'}></Box>

              <ListItemText
                primary={
                  <Typography variant="h6">{flow.displayName}</Typography>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {flow.description || 'No description available'}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setCurrentFlow(null);
          setDialogOpen(false);
        }}
      >
        <DialogTitle>Are you sure you want to delete this flow?</DialogTitle>
        <DialogContent>{deleteFlowLoading && <LoadingPage />}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCurrentFlow(null);
              setDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              if (currentFlow) {
                await onDeleteFlowClicked(currentFlow);
                setDialogOpen(false);
              }
            }}
          >
            Delete Flow
          </Button>
        </DialogActions>
      </Dialog>
      {deleteFlowLoading && <LoadingPage />}

      <Box marginTop={'25px'}>
        <Button variant={'contained'} onClick={onAddFlowClicked}>
          Add a Flow
        </Button>
      </Box>
    </Box>
  );
}
