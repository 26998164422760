// Interface for a single Transition Route
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../api/api';
import { API_ROUTES } from '../config/apiRoutes';

export interface TransitionRoute {
  intent?: string;
  condition?: string;
  triggerFulfillment?: Fulfillment;
  targetPage?: string;
  targetFlow?: string;
  name: string;
  description: string;
}

interface Fulfillment {
  messages?: Message[];
  webhook?: string;
}

interface Message {
  text?: {
    text: string[];
  };
  payload?: Record<string, unknown>;
}

export interface TransitionRouteGroup {
  name?: string;
  agentId?: string;
  flowId?: string;
  intentId?: string;
  pageId?: string;
  displayName: string;
  transitionRoutes: TransitionRoute[];
}

export const initialTransitionRoute: TransitionRoute = {
  intent: '', // Default empty string for optional intent
  condition: '', // Default empty string for optional condition
  triggerFulfillment: {
    messages: [], // Empty array indicating no initial fulfillment messages
  },
  targetPage: '', // Default empty string for optional target page
  targetFlow: '', // Default empty string for optional target flow
  name: '', // Required name field, initialized as an empty string
  description: '', // Required description field, initialized as an empty string
};

const tagTypes = ['RouteTransitionGroups'];

export const routeTransitionGroupsApi = createApi({
  reducerPath: 'routeTransitionGroupsApi',
  baseQuery: baseQuery,
  tagTypes: tagTypes,
  endpoints: (builder) => ({
    getRouteTransitionGroups: builder.query<
      TransitionRoute[],
      { agentId: string; flowId: string }
    >({
      query: ({ agentId, flowId }) =>
        API_ROUTES.ROUTE_TRANSITION_GROUPS.GET_TRANSITIONS_ROUTE_GROUPS(
          agentId,
          flowId
        ),
      providesTags: tagTypes,
    }),
    getRouteTransitionGroup: builder.query<
      TransitionRoute,
      { agentId: string; flowId: string; pageId: string }
    >({
      query: ({ agentId, flowId, pageId }) =>
        API_ROUTES.ROUTE_TRANSITION_GROUPS.GET_TRANSITION_ROUTE_GROUP(
          agentId,
          flowId,
          pageId
        ),
      providesTags: tagTypes,
    }),
    createRouteTransitionGroup: builder.mutation<
      TransitionRoute,
      Partial<TransitionRoute>
    >({
      query: (newPage) => ({
        url: API_ROUTES.ROUTE_TRANSITION_GROUPS.CREATE_TRANSITION_ROUTE_GROUP,
        method: 'POST',
        body: newPage,
      }),
      invalidatesTags: tagTypes,
    }),
    updateRouteTransitionGroup: builder.mutation<
      TransitionRoute,
      Partial<TransitionRoute>
    >({
      query: (updatedPage) => ({
        url: API_ROUTES.ROUTE_TRANSITION_GROUPS.UPDATE_TRANSITION_ROUTE_GROUP,
        method: 'PATCH',
        body: updatedPage,
      }),
      invalidatesTags: tagTypes,
    }),
    deleteRouteTransitionGroup: builder.mutation<
      void,
      { agentId: string; flowId: string; groupId: string }
    >({
      query: ({ agentId, flowId, groupId }) => ({
        url: API_ROUTES.ROUTE_TRANSITION_GROUPS.DELETE_TRANSITION_ROUTE_GROUP(
          agentId,
          flowId,
          groupId
        ),
        method: 'DELETE',
      }),
      invalidatesTags: tagTypes,
    }),
  }),
});

export const {
  useGetRouteTransitionGroupsQuery,
  useGetRouteTransitionGroupQuery,
  useCreateRouteTransitionGroupMutation,
  useDeleteRouteTransitionGroupMutation,
  useUpdateRouteTransitionGroupMutation,
} = routeTransitionGroupsApi;
