import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useCreateReceptionistMutation } from '../../store/receptionistSlice';
import { showErrorToast, showSuccessToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import LoadingPage from '../LoadingWidget';
import { useNavigate } from 'react-router-dom';

export default function CreateReceptionist() {
  const [name, setName] = useState('');
  const [createReceptionist, { isLoading, error, isSuccess }] =
    useCreateReceptionistMutation();

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await createReceptionist({ displayName: name }).unwrap();
      showSuccessToast(
        `Successfully created a receptionist with the name ${name}!`
      );
      navigate('/receptionists/mine');
    } catch (e) {
      showErrorToast(`Failed to create receptionist, ${getErrorMessage(e)}`);
    }
  };

  return (
    <Box component={'form'} onSubmit={handleSubmit}>
      <TextField
        label="Receptionist Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isLoading}
      >
        {isLoading ? 'Creating...' : 'Create Receptionist'}
      </Button>
      {isLoading && <LoadingPage />}

      {error && (
        <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
          Error: {getErrorMessage(error)}
        </Typography>
      )}
      {isSuccess && (
        <Typography
          color="primary"
          variant="body2"
          style={{ marginTop: '10px' }}
        >
          Receptionist created successfully!
        </Typography>
      )}
    </Box>
  );
}
