import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateFlowMutation } from '../../store/flowsApi';
import { showSuccessToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import ErrorWidget from '../error-helpers/ErrorWidget';
import LoadingPage from '../LoadingWidget';

export function CreateReceptionistFlow() {
  const { agentId } = useParams();

  const [displayName, setDisplayName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [error, setError] = useState<null | string>(null);
  const navigate = useNavigate();

  const [createFlow, { isLoading }] = useCreateFlowMutation();

  const onCreateFlowClicked = async () => {
    try {
      const flowCreateArgs = {
        agentId,
        displayName,
        description,
      };
      await createFlow(flowCreateArgs).unwrap();
      showSuccessToast('Successfully created this flow!');
      navigate(`/flows/view/${agentId}`);
    } catch (e) {
      setError(getErrorMessage(e));
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      padding={'25px'}
    >
      <Typography variant={'h4'}>Create Flow</Typography>
      <TextField
        fullWidth={true}
        label={'Display Name*'}
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
      ></TextField>

      <Box marginTop={'25px'}></Box>
      <TextField
        fullWidth={true}
        label={'Description*'}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></TextField>
      <Box marginTop={'25px'}>
        <Button variant={'contained'} onClick={onCreateFlowClicked}>
          Submit
        </Button>
      </Box>
      {error && <ErrorWidget error={error} />}
      {isLoading && <LoadingPage />}
    </Box>
  );
}
