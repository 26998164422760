import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../store/authApi';
import LoadingPage from './LoadingWidget';
import ErrorWidget from './error-helpers/ErrorWidget';
import { showErrorToast, showSuccessToast } from '../helpers/helpers';
import { getErrorMessage } from './error-helpers/errorHelpers';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../store/authSlice';

const SignUpForm: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [username, setUsername] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hospitalName, setHospitalName] = useState<string>('');
  const [hospitalUrl, setHospitalUrl] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [registerMutation, { isLoading, error }] = useRegisterMutation();

  const steps = ['Personal Info', 'Hospital Name', 'Hospital URL', 'Confirm'];

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  function validateUrl(url: string): boolean {
    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return urlPattern.test(url);
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputUrl = e.target.value;
    setHospitalUrl(inputUrl);

    if (!validateUrl(inputUrl)) {
      setUrlError(
        'Please enter a valid URL (e.g., www.example.com or example.com)'
      );
    } else {
      setUrlError('');
    }
  };

  const handleSubmit = async () => {
    const formattedHospitalUrl = hospitalUrl.match(/^https?:\/\//)
      ? hospitalUrl
      : `https://${hospitalUrl}`;

    try {
      const user = await registerMutation({
        username,
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        hospitalName,
        hospitalUrl: formattedHospitalUrl,
      }).unwrap();
      dispatch(setCurrentUser(user));
      showSuccessToast('Account successfully created!');
      if (user.agents.length > 0) {
        navigate(`/edit/${user.agents[0].id}`);
      } else {
        navigate('/receptionists/create');
      }
    } catch (e) {
      showErrorToast(`Failed to create account. Reason: ${getErrorMessage(e)}`);
    }
  };

  const isFormValidStep1 = () => {
    return (
      username.trim() !== '' &&
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      email.trim() !== '' &&
      phoneNumber.trim() !== '' &&
      password.trim() !== ''
    );
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      sx={{
        maxWidth: 400,
        margin: 'auto',
        marginTop: '50px',
        padding: 4,
        boxShadow: 3,
        borderRadius: 2,
        textAlign: 'center',
        bgcolor: 'background.paper',
      }}
    >
      <Stepper activeStep={step} alternativeLabel sx={{ marginBottom: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {step === 0 && (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Sign Up
          </Typography>

          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button disabled>Back</Button>
            <Button
              onClick={handleNextStep}
              variant="contained"
              color="primary"
              disabled={!isFormValidStep1()}
            >
              Next
            </Button>
          </Box>
        </>
      )}

      {step === 1 && (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Enter Hospital Name
          </Typography>

          <TextField
            label="Hospital Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={hospitalName}
            onChange={(e) => setHospitalName(e.target.value)}
            required
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              onClick={handlePreviousStep}
              variant="outlined"
              color="primary"
            >
              Back
            </Button>
            <Button
              onClick={handleNextStep}
              variant="contained"
              color="primary"
              disabled={!hospitalName.trim()}
            >
              Next
            </Button>
          </Box>
        </>
      )}

      {step === 2 && (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Enter Hospital Website URL
          </Typography>

          <TextField
            label="Hospital Website URL"
            variant="outlined"
            fullWidth
            margin="normal"
            type="url"
            value={hospitalUrl}
            onChange={handleUrlChange}
            required
            error={!!urlError}
            helperText={urlError || 'You can enter the URL without https://'}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              onClick={handlePreviousStep}
              variant="outlined"
              color="primary"
            >
              Back
            </Button>
            <Button
              onClick={handleNextStep}
              variant="contained"
              color="primary"
              disabled={!!urlError || !hospitalUrl.trim()}
            >
              Next
            </Button>
          </Box>
        </>
      )}

      {step === 3 && (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Confirm Information
          </Typography>

          <Box sx={{ textAlign: 'left', mt: 2 }}>
            <Typography variant="h6">Personal Information</Typography>
            <Typography variant="body1">Username: {username}</Typography>
            <Typography variant="body1">
              Name: {firstName} {lastName}
            </Typography>
            <Typography variant="body1">Email: {email}</Typography>
            <Typography variant="body1">Phone Number: {phoneNumber}</Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
              Hospital Information
            </Typography>
            <Typography variant="body1">
              Hospital Name: {hospitalName}
            </Typography>
            <Typography variant="body1">
              Hospital Website URL:{' '}
              {hospitalUrl.startsWith('http')
                ? hospitalUrl
                : `https://${hospitalUrl}`}
            </Typography>
          </Box>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            Confirm & Sign Up
          </Button>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
            <Button
              onClick={handlePreviousStep}
              variant="outlined"
              color="primary"
            >
              Back
            </Button>
          </Box>
        </>
      )}

      <Box sx={{ mt: 2 }}>{isLoading && <LoadingPage />}</Box>
      <Box sx={{ mt: 2 }}>{error && <ErrorWidget error={error} />}</Box>
    </Box>
  );
};

export default SignUpForm;
