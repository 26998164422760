import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useFetchFlowQuery,
  useFetchFlowsQuery,
  useUpdateFlowMutation,
} from '../../store/flowsApi';
import LoadingPage from '../LoadingWidget';
import ErrorWidget from '../error-helpers/ErrorWidget';
import { TransitionRoute } from '../../store/routeTransitionGroupApi';
import { Add, Delete } from '@mui/icons-material';
import { showErrorToast, showSuccessToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import {
  Message,
  useFetchReceptionistIntentsQuery,
} from '../../store/receptionistSlice';
import { isMobile } from 'react-device-detect';

export default function ViewFlowTransitionRoute() {
  const { agentId } = useParams();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get('flowId');
  const transitionRouteName = searchParams.get('routeName');
  const [agentResponse, setAgentResponse] = useState('');
  const [agentResponses, setAgentResponses] = useState<string[]>([]);
  const [routeDescription, setRouteDescription] = useState<string | null>(null);
  const [selectedIntent, setSelectedIntent] = useState<
    string | null | undefined
  >(null);
  const [targetFlow, setTargetFlow] = useState<null | string | undefined>(null);

  const {
    data: flows,
    isLoading: flowsLoading,
    error: flowsError,
  } = useFetchFlowsQuery(agentId ?? '');

  const [updateTransitionRoute, { isLoading: updatingFlow }] =
    useUpdateFlowMutation();

  const handleAddResponse = () => {
    if (agentResponse.trim()) {
      setAgentResponses([...agentResponses, agentResponse.trim()]);
      setAgentResponse('');
    }
  };

  const handleRemoveResponse = (index: number) => {
    setAgentResponses(agentResponses.filter((_, i) => i !== index));
  };

  const {
    data: flow,
    error,
    isLoading,
  } = useFetchFlowQuery({
    agentId: agentId ?? '',
    flowId: flowId ?? '',
  });

  const {
    data: intents,
    error: intentsError,
    isLoading: intentsLoading,
  } = useFetchReceptionistIntentsQuery(agentId ?? '');

  const onSaveChanges = async () => {
    try {
      if (!flow) {
        return;
      }
      const route: number | undefined = flow.transitionRoutes?.findIndex(
        (s) => s.name === transitionRouteName
      );

      if (route === undefined) {
        return;
      }

      const triggerFulfillment = {
        messages: [
          {
            text: { text: [...agentResponses] },
          },
        ],
      };

      const updatedFlow = structuredClone(flow);
      if (!updatedFlow.transitionRoutes) {
        return;
      }

      updatedFlow.transitionRoutes[route].triggerFulfillment =
        triggerFulfillment;
      updatedFlow.transitionRoutes[route].description = routeDescription;
      updatedFlow.transitionRoutes[route].targetFlow = targetFlow;
      updatedFlow.transitionRoutes[route].intent = selectedIntent;

      await updateTransitionRoute(updatedFlow).unwrap();
      showSuccessToast('Successfully updated this transition route!');
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const [transitionRoute, setTransitionRoute] =
    useState<null | TransitionRoute>(null);

  useEffect(() => {
    if (!isLoading && !error && flow && transitionRouteName) {
      const route: TransitionRoute | undefined = flow.transitionRoutes?.find(
        (s) => s.name === transitionRouteName
      );

      if (!route) {
        return;
      }
      setTransitionRoute(route);
      setRouteDescription(route.description);
      setTargetFlow(route.targetFlow);
      setSelectedIntent(route.intent);

      const fulfillment = route.triggerFulfillment;
      if (!fulfillment) {
        return;
      }

      const messages: Message[] | undefined = fulfillment.messages;
      if (!messages || messages.length === 0) {
        return;
      }

      setAgentResponses(messages[0].text?.text ?? []);
    }

    if (!intentsLoading && !intentsError && intents && intents.length > 0) {
      if (flow && transitionRouteName) {
        const route: TransitionRoute | undefined = flow.transitionRoutes?.find(
          (s) => s.name === transitionRouteName
        );

        if (!route || !route.intent) {
          return;
        }
        setSelectedIntent(route.intent);
      } else {
        setSelectedIntent(intents[0].name);
      }
    }
  }, [
    isLoading,
    flow,
    error,
    transitionRouteName,
    intentsLoading,
    intentsError,
    intents,
  ]);

  if (!transitionRouteName) {
    return ErrorWidget({ error: 'No transition name specified.' });
  }

  if (isLoading) {
    return LoadingPage();
  }

  if (error) {
    return ErrorWidget({ error });
  }
  if (!flow) {
    return ErrorWidget({ error: 'No data to display.' });
  }

  return (
    <Box margin={isMobile ? '0px' : '25px'}>
      <Typography variant="h5" gutterBottom>
        {routeDescription ?? ''}
      </Typography>
      {flow && (
        <Typography variant={'h6'}>Flow name: {flow.displayName}</Typography>
      )}
      {!transitionRoute && <LoadingPage />}

      {transitionRoute && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>
                  <TextField
                    value={routeDescription}
                    onChange={(e) => {
                      setRouteDescription(e.target.value);
                    }}
                    fullWidth
                    label={'Description'}
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Intent</TableCell>
                <TableCell>
                  {!intentsLoading && !intentsError && intents && (
                    <Select
                      fullWidth={true}
                      value={selectedIntent}
                      label="Select an Intent"
                      onChange={(e) => {
                        setSelectedIntent(e.target.value);
                      }}
                    >
                      {intents.map((intent) => {
                        return (
                          <MenuItem key={intent.name} value={intent.name}>
                            {intent.displayName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Condition</TableCell>
                <TableCell>{transitionRoute.condition || 'None'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Target Page</TableCell>
                <TableCell>{transitionRoute.targetPage || 'None'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Target Flow</TableCell>
                <TableCell>
                  {!flowsLoading && !flowsError && flows && (
                    <Select
                      fullWidth={true}
                      value={targetFlow}
                      label="Select a target Flow"
                      onChange={(e) => {
                        setTargetFlow(e.target.value);
                      }}
                    >
                      {flows.map((flow) => {
                        return (
                          <MenuItem key={flow.name} value={flow.name}>
                            {flow.displayName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Box display="flex" alignItems="center" gap={2} mt={2}>
            <TextField
              label="Agent Response"
              fullWidth
              value={agentResponse}
              onChange={(e) => setAgentResponse(e.target.value)}
              placeholder="Type a response and add to the list"
            />
            <IconButton color="primary" onClick={handleAddResponse}>
              <Add />
            </IconButton>
          </Box>

          <List>
            {agentResponses.map((text, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveResponse(index)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </TableContainer>
      )}
      <Box marginTop={'25px'} onClick={onSaveChanges}>
        <Button variant={'contained'}>Save Changes</Button>
      </Box>
      {updatingFlow && <LoadingPage />}
    </Box>
  );
}
