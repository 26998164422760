import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ROUTES } from '../config/apiRoutes';
import { baseQuery } from '../api/api';

interface Fulfillment {
  messages: Message[];
  webhook?: string;
}

interface Message {
  text?: {
    text: string[];
  };
  payload?: Record<string, unknown>;
}

interface EventHandler {
  event: string;
  triggerFulfillment?: Fulfillment;
  targetPage?: string;
  targetFlow?: string;
}

interface TransitionRoute {
  intent?: string;
  condition?: string;
  triggerFulfillment?: Fulfillment;
  targetPage?: string;
  targetFlow?: string;
}

export interface Page {
  name?: string;
  displayName: string;
  entryFulfillment?: Fulfillment;
  form?: unknown;
  eventHandlers?: EventHandler[];
  transitionRoutes?: TransitionRoute[];
  transitionRouteGroups?: string[];
}

const tagTypes = ['Pages'];

export const pagesApi = createApi({
  reducerPath: 'pagesApi',
  baseQuery: baseQuery,
  tagTypes: tagTypes,
  endpoints: (builder) => ({
    getPages: builder.query<Page[], { agentId: string; flowId: string }>({
      query: ({ agentId, flowId }) =>
        API_ROUTES.PAGES.GET_PAGES(agentId, flowId),
      providesTags: tagTypes,
    }),
    getPage: builder.query<
      Page,
      { agentId: string; flowId: string; pageId: string }
    >({
      query: ({ agentId, flowId, pageId }) =>
        API_ROUTES.PAGES.GET_PAGE(agentId, flowId, pageId),
      providesTags: tagTypes,
    }),
    createPage: builder.mutation<Page, Partial<Page>>({
      query: (newPage) => ({
        url: API_ROUTES.PAGES.CREATE_PAGE,
        method: 'POST',
        body: newPage,
      }),
      invalidatesTags: tagTypes,
    }),
    updatePage: builder.mutation<Page, Partial<Page>>({
      query: (updatedPage) => ({
        url: API_ROUTES.PAGES.UPDATE_PAGE,
        method: 'PATCH',
        body: updatedPage,
      }),
      invalidatesTags: tagTypes,
    }),
    deletePage: builder.mutation<
      void,
      { agentId: string; flowId: string; pageId: string }
    >({
      query: ({ agentId, flowId, pageId }) => ({
        url: API_ROUTES.PAGES.DELETE_PAGE(agentId, flowId, pageId),
        method: 'DELETE',
      }),
      invalidatesTags: tagTypes,
    }),
    updatePageRoute: builder.mutation<Page, Partial<Page>>({
      query: (updatedPage) => ({
        url: API_ROUTES.PAGES.UPDATE_PAGE,
        body: updatedPage,
        method: 'POST',
      }),
      invalidatesTags: tagTypes,
    }),
  }),
});

export const {
  useGetPagesQuery,
  useGetPageQuery,
  useCreatePageMutation,
  useUpdatePageMutation,
  useDeletePageMutation,
  useUpdatePageRouteMutation,
} = pagesApi;
