import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import {
  TransitionRoute,
  TransitionRouteGroup,
  useCreateRouteTransitionGroupMutation,
} from '../../store/routeTransitionGroupApi';
import { useParams, useSearchParams } from 'react-router-dom';
import ErrorWidget from '../error-helpers/ErrorWidget';
import { showErrorToast, showSuccessToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import { useFetchReceptionistIntentsQuery } from '../../store/receptionistSlice';
import LoadingPage from '../LoadingWidget';

export default function CreateTransitionRouteGroup() {
  const { agentId } = useParams();
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get('flowId');

  const [displayName, setDisplayName] = useState('');
  const [selectedIntent, setSelectedIntent] = useState<null | string>(null);
  //const [agentResponses, setAgentResponses] = useState<string[]>([]);

  const {
    data: intents,
    isLoading: intentsLoading,
    error: intentsError,
  } = useFetchReceptionistIntentsQuery(agentId ?? '');

  const [
    addRouteTransitionGroup,
    {
      isLoading: routeTransitionGroupCreateLoading,
      error: routeTransitionGroupCreateError,
    },
  ] = useCreateRouteTransitionGroupMutation();

  useEffect(() => {
    if (!intentsLoading && !intentsError && intents) {
      setSelectedIntent(intents[0].name);
    }
  }, [intents, intentsError, intentsLoading]);

  const handleCreate = async () => {
    try {
      if (intentsLoading) {
        return;
      }

      const transitionRoute: TransitionRoute = {
        description: '',
        name: '',
        intent: selectedIntent ?? '',
        //condition: `intent.name == '${selectedIntent}'`,
        triggerFulfillment: {
          messages: [
            {
              text: {
                text: ['sample message'],
              },
            },
          ],
        },
      };

      const trgPayload: Partial<TransitionRouteGroup> = {
        agentId: agentId as string,
        flowId: flowId as string,
        displayName,
        transitionRoutes: [transitionRoute],
      };

      await addRouteTransitionGroup(trgPayload).unwrap();
      showSuccessToast('Transition Route Group created successfully:');
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
      padding={'25px'}
    >
      <Typography>Create Transition Route Group</Typography>

      {routeTransitionGroupCreateLoading && <CircularProgress />}

      {routeTransitionGroupCreateError && (
        <ErrorWidget error={routeTransitionGroupCreateError} />
      )}

      <TextField
        label="Display Name"
        value={displayName}
        onChange={(e) => setDisplayName(e.target.value)}
        fullWidth
        margin="normal"
      />

      {intentsLoading && <LoadingPage />}
      {!intentsLoading && !intentsError && intents && (
        <Box width={'100%'} marginTop={'25px'} marginBottom={'25px'}>
          <Select
            fullWidth={true}
            value={selectedIntent}
            label="Select an Option"
            onChange={(e) => {
              setSelectedIntent(e.target.value);
            }}
          >
            {intents.map((intent) => {
              return (
                <MenuItem key={intent.name} value={intent.name}>
                  {intent.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleCreate}
        disabled={routeTransitionGroupCreateLoading}
        fullWidth
      >
        Create Transition Route Group
      </Button>
    </Box>
  );
}
