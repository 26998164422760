import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReceptionistTelephone } from './receptionistSlice';

export interface ReceptionistUser {
  id: string;
  email: string;
  username: string;
  phoneNumber?: string;
  firstName: string;
  lastName: string;
  password: string;
  stripeCustomerId: string | null;
  stripeConnectedAccountId: string | null;
  hospitalName: string | null;
  hospitalUrl: string | null;
  hospitalContent: string | null;
  hasActiveSubscription: boolean;
  stripeSubscriptionId: string | null;
  subscribedUntil: string | null;
  agents: ReceptionistTelephone[];
}

export interface AuthState {
  username: string;
  token: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  loading: boolean;
  user: ReceptionistUser | null;
}

const initialState: AuthState = {
  username: '',
  token: null,
  status: 'idle',
  user: null,
  error: null,
  loading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedOut(state) {
      state.username = '';
      state.token = null;
      state.status = 'idle';
      state.error = null;
      state.user = null;
      state.username = '';
      state.loading = false;
    },
    setAuthLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setCurrentUser(state, action: PayloadAction<ReceptionistUser>) {
      state.user = action.payload;
    },
  },
});
export default authSlice.reducer;
export const { setCurrentUser, setLoggedOut } = authSlice.actions;
