// muiTheme.ts
import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#1B5E20', // Dark green for primary color
    },
    secondary: {
      main: '#FFFFFF', // White for secondary color
    },
    background: {
      default: '#FFFFFF', // White background
    },
    text: {
      primary: '#1B5E20', // Dark green for main text
      secondary: '#2E7D32', // Slightly lighter green for secondary text
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif', // Use Poppins font
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#1B5E20', // Dark green for headers
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: '#2E7D32', // Slightly lighter green for subheaders
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#1B5E20', // Dark green for body text
    },
    button: {
      textTransform: 'none', // Disable uppercase transformation for buttons
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1B5E20', // Dark green for AppBar
          color: '#FFFFFF', // White text for AppBar
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px', // Rounded corners for buttons
          fontFamily: '"Poppins", sans-serif', // Apply Poppins font to buttons
          color: '#FFFFFF', // White text on green button
          backgroundColor: '#1B5E20', // Dark green background for buttons
          '&:hover': {
            backgroundColor: '#2E7D32', // Slightly lighter green on hover
          },
        },
      },
    },
  },
});

export default muiTheme;
