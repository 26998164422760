import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../api/api';
import { API_ROUTES } from '../config/apiRoutes';
import { TransitionRoute } from './routeTransitionGroupApi';

// Flow.ts

interface EventHandler {
  event: string;
  triggerFulfillment?: Fulfillment;
  targetPage?: string;
  targetFlow?: string;
}

interface Fulfillment {
  messages?: Message[];
  webhook?: string;
}

interface Message {
  text?: TextMessage;
}

interface TextMessage {
  text: string[];
}

interface NluSettings {
  modelType: string;
  classificationThreshold: number;
}

export interface Flow {
  name?: string;
  displayName: string;
  description?: string;
  transitionRoutes?: TransitionRoute[];
  eventHandlers?: EventHandler[];
  nluSettings?: NluSettings;
  fulfillment?: Fulfillment;
  transitionRouteGroups?: string[];
  knowledgeConnectorSettings?: KnowledgeConnectorSettings;
  advancedSettings?: AdvancedSettings;
  agentId?: string | null;
}

interface KnowledgeConnectorSettings {
  enabled: boolean;
  knowledgeBaseNames: string[];
}

interface AdvancedSettings {
  loggingSettings?: LoggingSettings;
}

interface LoggingSettings {
  enableInteractionLogging: boolean;
  enableStackdriverLogging: boolean;
}

const tags = ['Flows'];

export const flowsApi = createApi({
  reducerPath: 'flows',
  baseQuery: baseQuery,
  tagTypes: tags,
  endpoints: (builder) => ({
    fetchFlows: builder.query<Flow[], string>({
      query: (agentId: string) => {
        return API_ROUTES.FLOWS.GET_FLOWS(agentId);
      },
      providesTags: tags,
    }),
    createFlow: builder.mutation<Flow, Partial<Flow>>({
      query: (flowBody) => ({
        url: API_ROUTES.FLOWS.CREATE_FLOW,
        body: flowBody,
        method: 'POST',
      }),
      invalidatesTags: tags,
    }),
    updateFlow: builder.mutation<Flow, Flow>({
      query: (flowBody) => ({
        url: API_ROUTES.FLOWS.UPDATE_FLOW,
        body: flowBody,
        method: 'PATCH',
      }),
      invalidatesTags: tags,
    }),
    fetchFlow: builder.query<Flow, { flowId: string; agentId: string }>({
      query: ({ flowId, agentId }) => {
        return API_ROUTES.FLOWS.GET_FLOW(flowId, agentId);
      },
      providesTags: tags,
    }),
    deleteFlow: builder.mutation<Flow, { flowId: string; agentId: string }>({
      query: ({ flowId, agentId }) => ({
        url: API_ROUTES.FLOWS.DELETE_FLOW(flowId, agentId),
        method: 'DELETE',
      }),
      invalidatesTags: tags,
    }),
  }),
});

export const {
  useFetchFlowsQuery,
  useCreateFlowMutation,
  useUpdateFlowMutation,
  useFetchFlowQuery,
  useDeleteFlowMutation,
} = flowsApi;
