import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import { receptionistsApiService } from './receptionistSlice';
import { flowsApi } from './flowsApi';
import { pagesApi } from './pagesApi';
import { authApi } from './authApi';
import { routeTransitionGroupsApi } from './routeTransitionGroupApi';

const store = configureStore({
  reducer: {
    auth: authSlice,
    [receptionistsApiService.reducerPath]: receptionistsApiService.reducer,
    [flowsApi.reducerPath]: flowsApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [routeTransitionGroupsApi.reducerPath]: routeTransitionGroupsApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(receptionistsApiService.middleware)
      .concat(flowsApi.middleware)
      .concat(pagesApi.middleware)
      .concat(authApi.middleware)
      .concat(routeTransitionGroupsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
