// src/config/apiRoutes.ts

export const API_ROUTES = {
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    LOGOUT: '/auth/logout',
    ME: '/auth/me',
  },
  TELEPHONES: {
    GET_TELEPHONES: '/telephones',
    RESERVE_PHONE_NUMBER: '/telephones/reserve',
    RELEASE_PHONE_NUMBER: '/telephones/release',
  },
  ROUTE_TRANSITION_GROUPS: {
    GET_TRANSITIONS_ROUTE_GROUPS: (agentId: string, flowId: string) =>
      `/route-groups/list?agentId=${agentId}&flowId=${flowId}`,
    GET_TRANSITION_ROUTE_GROUP: (
      agentId: string,
      flowId: string,
      groupId: string
    ) => `/route-groups/details/${groupId}?agentId=${agentId}&flowId=${flowId}`,
    CREATE_TRANSITION_ROUTE_GROUP: `/route-groups/create`,
    UPDATE_TRANSITION_ROUTE_GROUP: `/route-groups/update`,
    DELETE_TRANSITION_ROUTE_GROUP: (
      agentId: string,
      flowId: string,
      groupId: string
    ) => `/route-groups/delete/${groupId}?agentId=${agentId}&flowId=${flowId}`,
  },
  FLOWS: {
    GET_FLOWS: (agentId: string) => `/flows/${agentId}`,
    CREATE_FLOW: '/flows/create',
    UPDATE_FLOW: '/flows/update',
    DELETE_FLOW: (flowId: string, agentId: string) =>
      `/flows/${flowId}?agentId=${agentId}`,
    GET_FLOW: (flowId: string, agentId: string) =>
      `/flows/details/${flowId}?agentId=${agentId}`,
  },
  PAGES: {
    GET_PAGES: (agentId: string, flowId: string) =>
      `/pages/list?agentId=${agentId}&flowId=${flowId}`,
    GET_PAGE: (agentId: string, flowId: string, pageId: string) =>
      `/pages/details/${pageId}?agentId=${agentId}&flowId=${flowId}`,
    CREATE_PAGE: '/pages/create',
    UPDATE_PAGE: '/pages/update',
    DELETE_PAGE: (agentId: string, flowId: string, pageId: string) =>
      `/pages/delete/${pageId}?agentId=${agentId}&flowId=${flowId}`,
    UPDATE_PAGE_ROUTE: '/pages/routes/update',
  },
  RECEPTIONISTS: {
    DELETE_RECEPTIONIST: (agentId: string) => `/agents/${agentId}`,
    DELETE_INTENT: (agentId: string, intentId: string) =>
      `/agents/intents/delete/${intentId}?agentId=${agentId}`,
    CREATE: '/agents/create',
    CREATE_INTENT: '/agents/intents/create',
    GET: '/agents/',
    GET_AGENT_BY_ID: (id: string) => `/agents/${id}`,
    UPDATE: '/agents',
    UPDATE_INTENT: '/agents/intents/update',
    GET_INTENT_DETAILS: (agentId: string, intentId: string) =>
      `agents/intents/details/${intentId}?agentId=${agentId}`,
    GET_INTENTS_BY_AGENT_ID: (id: string) => `/agents/intents/${id}`,
    GET_CALL_BY_ID: `/agents/calls/`,
    GET_CALL_METRICS: `/agents/calls/metrics`,
  },
  USERS: {
    GET_USER: '/users/attrs',
    GET_USER_BY_ID: (id: string) => `/users/details/${id}`,
    CREATE_USER: '/users',
    UPDATE_USER: (id: string) => `/users/${id}`,
    DELETE_USER: (id: string) => `/users/${id}`,
  },
  STRIPE: {
    CREATE_CARDS: `/stripe/cards/create`,
    ALL_CARDS: `/stripe/cards/all`,
    DELETE_CARD: `/stripe/cards/delete`,
    CREATE_SUBSCRIPTION: `/stripe/subscriptions/create`,
    ACTIVE_SUBSCRIPTIONS: `/stripe/subscriptions/all`,
    CANCEL_SUBSCRIPTION: `/stripe/subscriptions/cancel`,
  },
  POSTS: {
    GET_POSTS: '/posts',
    GET_POST_BY_ID: (id: string) => `/posts/${id}`,
    CREATE_POST: '/posts',
    UPDATE_POST: (id: string) => `/posts/${id}`,
    DELETE_POST: (id: string) => `/posts/${id}`,
  },
  // Add more routes as needed
};
