import React, { useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

export default function ReceptionistTabs() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  // Redirects to /receptionists/overview on first render
  useEffect(() => {
    if (location.pathname === '/receptionists/overview') {
      navigate('/receptionists/mine', { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={isMobile ? '10px' : '20px'}
      overflow="hidden"
    >
      <Tabs
        value={location.pathname}
        onChange={handleTabChange}
        variant={isMobile ? 'scrollable' : 'standard'}
        scrollButtons="auto"
        sx={{
          maxWidth: isMobile ? '100%' : '600px',
          '& .MuiTabs-flexContainer': {
            justifyContent: 'center',
          },
        }}
        aria-label="receptionist navigation tabs"
      >
        <Tab label="My Receptionists" value="/receptionists/mine" />
        <Tab label="Create a Receptionist" value="/receptionists/create" />
      </Tabs>
      <Box width={'100%'} marginTop="25px">
        <Outlet />
      </Box>
    </Box>
  );
}
