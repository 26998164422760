import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { useCreateMessageMutation } from '../../store/receptionistSlice';
import { useParams } from 'react-router-dom';

interface MessageAttributes {
  receptionistId: string;
  sender: 'AI' | 'USER';
  message: string;
}

const TestReceptionist: React.FC = () => {
  const [messages, setMessages] = useState<MessageAttributes[]>([]);
  const [input, setInput] = useState<string>('');
  const { id: receptionistId } = useParams<string>();

  const [createMessage, { isLoading }] = useCreateMessageMutation();

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage: MessageAttributes = {
      receptionistId: receptionistId ?? '',
      sender: 'USER',
      message: input.trim(),
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    const chatRequest = {
      conversationHistory: [...messages, userMessage],
      latestMessage: input.trim(),
      id: receptionistId ?? '',
    };

    setInput('');

    try {
      const response = await createMessage(chatRequest).unwrap();

      const aiResponse: MessageAttributes = {
        receptionistId: receptionistId ?? '',
        sender: 'AI',
        message: response.response,
      };

      setMessages((prevMessages) => [...prevMessages, aiResponse]);
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: '100%', md: '60%' },
        margin: 'auto',
        mt: { xs: 2, md: 4 },
        padding: { xs: 2, md: 4 },
        boxShadow: 3,
        borderRadius: 2,
        bgcolor: 'background.paper',
        height: { xs: '80vh', md: '90vh' },
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5" gutterBottom textAlign="center">
        Chat with your AI receptionist
      </Typography>

      <List
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          mb: 2,
          padding: 0,
          bgcolor: 'background.default',
          borderRadius: 1,
        }}
      >
        {messages.map((msg, index) => (
          <ListItem
            key={index}
            disableGutters
            sx={{
              justifyContent: msg.sender === 'USER' ? 'flex-end' : 'flex-start',
            }}
          >
            <Box
              sx={{
                bgcolor: msg.sender === 'USER' ? 'primary.main' : 'grey.300',
                color: msg.sender === 'USER' ? 'white' : 'black',
                p: 1.5,
                borderRadius: 2,
                maxWidth: '75%',
                wordWrap: 'break-word',
              }}
            >
              <ListItemText
                primary={msg.message}
                primaryTypographyProps={{
                  variant: 'body1',
                  sx: { color: msg.sender === 'USER' ? 'white' : 'black' },
                }}
              />
            </Box>
          </ListItem>
        ))}
        {isLoading && (
          <ListItem disableGutters sx={{ justifyContent: 'flex-end' }}>
            <Box
              sx={{
                bgcolor: 'primary.main',
                color: 'white',
                p: 1.5,
                borderRadius: 2,
                maxWidth: '75%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} />
              <Typography variant="body2">Sending...</Typography>
            </Box>
          </ListItem>
        )}
      </List>

      <Box sx={{ display: 'flex', gap: 1 }}>
        <TextField
          label="Type a message"
          variant="outlined"
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          sx={{ bgcolor: 'background.paper', flexGrow: 1 }}
        />
        <Button
          onClick={handleSendMessage}
          variant="contained"
          color="primary"
          disabled={isLoading}
          sx={{
            minWidth: { xs: 'auto', md: '100px' },
            height: '100%',
          }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default TestReceptionist;
