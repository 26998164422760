// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material'; // Import MUI's ThemeProvider and CssBaseline
import muiTheme from './muiTheme';
import { Elements } from '@stripe/react-stripe-js'; // Import the MUI theme
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY!, {
  apiVersion: '2024-09-30.acacia',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <BrowserRouter>
          <MuiThemeProvider theme={muiTheme}>
            <CssBaseline /> {/* Reset browser styling to apply the MUI theme */}
            <App />
            <ToastContainer />
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    </Elements>
  </React.StrictMode>
);

reportWebVitals();
