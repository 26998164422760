import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { setCurrentUser } from '../store/authSlice';
import { showErrorToast, showSuccessToast } from '../helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../store/authApi';
import LoadingPage from './LoadingWidget';
import ErrorWidget from './error-helpers/ErrorWidget';
import { getErrorMessage } from './error-helpers/errorHelpers';

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const [loginMutation, { isLoading, error }] = useLoginMutation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (authState.loading) {
      return;
    }
    try {
      const result = await loginMutation({ username, password }).unwrap();
      const user = { ...result };
      dispatch(setCurrentUser(user));
      showSuccessToast('Successfully signed you in!');
      if (user.agents.length > 0) {
        navigate(`/edit/${user.agents[0].id}`);
      } else {
        navigate('/receptionists/create');
      }
    } catch (e) {
      showErrorToast(`Failed to log you in. Reason: ${getErrorMessage(e)}`);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 400,
        margin: 'auto',
        marginTop: '50px',
        padding: 4,
        boxShadow: 3,
        borderRadius: 2,
        textAlign: 'center',
        bgcolor: 'background.paper',
      }}
    >
      {/* Welcome Text */}
      <Typography variant="h5" component="h2" gutterBottom sx={{ mb: 3 }}>
        Welcome to Darwin AI
      </Typography>

      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      <TextField
        label="Username"
        variant="outlined"
        fullWidth
        margin="normal"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        disabled={isLoading}
      >
        {isLoading ? 'Logging in...' : 'Login'}
      </Button>

      {/* Loading/Error handling */}
      <Box sx={{ mt: 2 }}>{isLoading && <LoadingPage />}</Box>
      <Box sx={{ mt: 2 }}>{error && <ErrorWidget error={error} />}</Box>

      {/* Additional links for Sign Up and Forgot Password */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="body2">
          <Link
            component="button"
            onClick={() => navigate('/forgot-password')}
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              mr: 2,
              color: 'text.secondary',
            }}
          >
            Forgot Password?
          </Link>
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          <Box component="span" fontWeight="bold" color="primary.main">
            Dont have an account?{' '}
          </Box>
          <Link
            component="button"
            onClick={() => navigate('/register')}
            sx={{
              fontWeight: 'bold',
              textDecoration: 'underline',
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            Sign up here
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginForm;
