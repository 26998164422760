import { toast } from 'react-toastify';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export function showErrorToast(errorMessage: string) {
  toast.error(errorMessage, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function showWarningToast(warningMessage: string) {
  toast.warning(warningMessage, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function showSuccessToast(successMessage: string) {
  toast.success(successMessage, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function getAppLoadingIndicator() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress
        size={60} // Size of the spinner
        thickness={4.5} // Thickness of the spinner's stroke
        sx={{
          color: '#1976d2', // Color of the spinner
        }}
      />
    </Box>
  );
}
