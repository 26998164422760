// src/components/ViewFlowPages.tsx

import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@mui/material';
import { useGetPagesQuery } from '../../store/pagesApi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ErrorWidget from '../error-helpers/ErrorWidget';
import LoadingPage from '../LoadingWidget';
import { Page } from '../../store/pagesApi';
import AddIcon from '@mui/icons-material/Add'; // Ensure that this matches the location of your Page interface

export default function ViewFlowPages() {
  const { agentId } = useParams();
  const [params] = useSearchParams();
  const flowId = params.get('flowId');
  const navigate = useNavigate();

  const {
    data: pages,
    isLoading,
    error,
  } = useGetPagesQuery({ agentId: agentId ?? '', flowId: flowId ?? '' });

  if (error) {
    return <ErrorWidget error={error} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!pages || pages.length === 0) {
    return (
      <Box mt={5}>
        <Typography>There are no pages associated this flow.</Typography>
        <Typography>Click the button below to create one.</Typography>

        <Button
          variant={'contained'}
          onClick={() => {
            navigate('/flows/pages/create');
          }}
        >
          <AddIcon />
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        View Flow Pages
      </Typography>
      {pages.map((page: Page, index) => (
        <Card key={index} variant="outlined" style={{ marginBottom: '16px' }}>
          <CardContent>
            <Typography variant="h6">{page.displayName}</Typography>

            {/* Entry Fulfillment Messages */}
            {page.entryFulfillment?.messages && (
              <Box marginTop={2}>
                <Typography variant="subtitle1">Entry Fulfillment:</Typography>
                <List>
                  {page.entryFulfillment.messages.map((message, i) => (
                    <ListItem key={i}>
                      <ListItemText primary={message.text?.text.join(', ')} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {/* Event Handlers */}
            {page.eventHandlers && page.eventHandlers.length > 0 && (
              <Box marginTop={2}>
                <Typography variant="subtitle1">Event Handlers:</Typography>
                <List>
                  {page.eventHandlers.map((handler, i) => (
                    <ListItem key={i}>
                      <ListItemText
                        primary={`Event: ${handler.event}`}
                        secondary={
                          handler.triggerFulfillment?.messages
                            ? `Trigger: ${handler.triggerFulfillment.messages.map(
                                (msg) => msg.text?.text.join(', ')
                              )}`
                            : 'No trigger fulfillment'
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}

            {/* Transition Routes */}
            {page.transitionRoutes && page.transitionRoutes.length > 0 && (
              <Box marginTop={2}>
                <Typography variant="subtitle1">Transition Routes:</Typography>
                <List>
                  {page.transitionRoutes.map((route, i) => (
                    <ListItem key={i}>
                      <ListItemText
                        primary={`Intent: ${route.intent || 'N/A'}`}
                        secondary={`Condition: ${route.condition || 'None'}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
