import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  Card,
  CardContent,
  CardActions,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Receptionist,
  useDeleteReceptionistMutation,
  useFetchReceptionistsQuery,
  useCreateReceptionistMutation,
} from '../../store/receptionistSlice';
import ErrorWidget from '../error-helpers/ErrorWidget';
import LoadingPage from '../LoadingWidget';
import EditIcon from '@mui/icons-material/Edit';
import { showErrorToast, showSuccessToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export default function ViewReceptionists() {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const {
    data: receptionists,
    error,
    isLoading,
  } = useFetchReceptionistsQuery();
  const [deleteReceptionist, { isLoading: deletingReceptionist }] =
    useDeleteReceptionistMutation();
  const [createReceptionist, { isLoading: creatingReceptionist }] =
    useCreateReceptionistMutation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [currentReceptionist, setCurrentReceptionist] =
    useState<Receptionist | null>(null);
  const [newReceptionistName, setNewReceptionistName] = useState<string>('');

  useEffect(() => {
    if (user && user.agents && user.agents.length > 0) {
      navigate(`/edit/${user.agents[0].id}/`);
    }
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorWidget error={error} />;
  }

  const onDeleteButtonConfirmed = async (receptionist: Receptionist) => {
    try {
      await deleteReceptionist(receptionist.id).unwrap();
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const handleCreateReceptionist = async () => {
    try {
      await createReceptionist({ displayName: newReceptionistName }).unwrap();
      showSuccessToast(
        `Successfully created a receptionist with the name ${newReceptionistName}!`
      );
      setCreateDialogOpen(false);
      setNewReceptionistName('');
    } catch (e) {
      showErrorToast(`Failed to create receptionist, ${getErrorMessage(e)}`);
    }
  };

  const getAvatarLetters = (name: string) => {
    const nameParts = name.split(' ');
    return nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : nameParts[0][0];
  };

  return (
    <Box>
      {receptionists && receptionists.length === 0 && (
        <Box textAlign="center" marginTop={4}>
          <Typography variant="h6" gutterBottom>
            No receptionists available
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateDialogOpen(true)}
          >
            Create Receptionist
          </Button>
        </Box>
      )}

      <Grid container spacing={3} marginTop={3}>
        {receptionists?.map((receptionist) => (
          <Grid item xs={12} sm={6} md={4} key={receptionist.id}>
            <Card elevation={3} sx={{ padding: 2 }}>
              <CardContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ marginRight: 2 }}>
                    {getAvatarLetters(receptionist.displayName)}
                  </Avatar>
                  <Box>
                    <Typography variant="h6">
                      {receptionist.displayName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {receptionist.telephone
                        ? receptionist.telephone.phoneNumber
                        : 'No phone associated'}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  onClick={() => navigate(`/edit/${receptionist.id}`)}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setCurrentReceptionist(receptionist);
                    setDialogOpen(true);
                  }}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Delete Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setCurrentReceptionist(null);
        }}
      >
        <DialogTitle>
          Are you sure you want to delete this receptionist? It will release the
          phone number with it as well.
        </DialogTitle>
        <DialogContent>{deletingReceptionist && <LoadingPage />}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setDialogOpen(false);
              setCurrentReceptionist(null);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={async () => {
              if (currentReceptionist) {
                await onDeleteButtonConfirmed(currentReceptionist);
                setDialogOpen(false);
                setCurrentReceptionist(null);
              }
            }}
          >
            Delete Receptionist
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Receptionist Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        <DialogTitle>Create New Receptionist</DialogTitle>
        <DialogContent>
          <TextField
            label="Receptionist Name"
            value={newReceptionistName}
            onChange={(e) => setNewReceptionistName(e.target.value)}
            fullWidth
            margin="normal"
          />
          {creatingReceptionist && <LoadingPage />}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setCreateDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateReceptionist}
            disabled={!newReceptionistName || creatingReceptionist}
          >
            {creatingReceptionist ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
