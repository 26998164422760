import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../api/api';
import { API_ROUTES } from '../config/apiRoutes';
import { ReceptionistUser } from './authSlice';

// CreditCard interface
export interface CreditCard {
  cardId?: null | string;
  cardholderName: string;
  cardNumber: string;
  expirationDate: string;
  cvv: string;
}

export interface StripeCreditCard {
  id: string;
  object: 'card';
  address_city: string | null;
  address_country: string | null;
  address_line1: string | null;
  address_line1_check: string | null;
  address_line2: string | null;
  address_state: string | null;
  address_zip: string | null;
  address_zip_check: string | null;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string | null;
  dynamic_last4: string | null;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  last4: string;
  metadata: Record<string, any>; // Using Record to accommodate any metadata structure
  name: string | null;
  tokenization_method: string | null;
  wallet: string | null;
}

export interface GetCardsResponse {
  success: boolean;
  cards: {
    data: StripeCreditCard[];
  };
}

export interface SubscriptionDetails {
  planId: string;
  planNickname: string;
  status: string;
  currentPeriodEnd: Date;
  currentPeriodStart: Date;
}

const tagTypes = ['UserAuth'];

export const authApi = createApi({
  reducerPath: 'authApiReducer',
  baseQuery: baseQuery,
  tagTypes,
  endpoints: (builder) => ({
    getUser: builder.query<ReceptionistUser, void>({
      query: () => API_ROUTES.USERS.GET_USER,
      providesTags: tagTypes,
    }),
    login: builder.mutation<
      ReceptionistUser,
      { username: string; password: string }
    >({
      query: (loginBody) => ({
        url: API_ROUTES.AUTH.LOGIN,
        method: 'POST',
        body: loginBody,
      }),
      invalidatesTags: tagTypes,
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: API_ROUTES.AUTH.LOGOUT,
        method: 'POST',
      }),
      invalidatesTags: tagTypes,
    }),

    register: builder.mutation<ReceptionistUser, Partial<ReceptionistUser>>({
      query: (registerBody) => ({
        url: API_ROUTES.AUTH.REGISTER,
        method: 'POST',
        body: registerBody,
      }),
      invalidatesTags: tagTypes,
    }),
    createCard: builder.mutation<void, { token: string }>({
      query: ({ token }) => ({
        url: API_ROUTES.STRIPE.CREATE_CARDS,
        body: { token },
        method: 'POST',
      }),
      invalidatesTags: tagTypes,
    }),
    getCards: builder.query<GetCardsResponse, void>({
      query: () => ({
        url: API_ROUTES.STRIPE.ALL_CARDS,
        method: 'GET',
      }),
      providesTags: tagTypes,
    }),

    deleteCard: builder.mutation<void, { cardId: string }>({
      query: ({ cardId }) => ({
        url: API_ROUTES.STRIPE.DELETE_CARD,
        method: 'POST',
        body: { cardId },
      }),
      invalidatesTags: tagTypes,
    }),
    createSubscription: builder.mutation<void, { paymentMethodId: string }>({
      query: ({ paymentMethodId }) => ({
        url: API_ROUTES.STRIPE.CREATE_SUBSCRIPTION,
        method: 'POST',
        body: { paymentMethodId },
      }),
      invalidatesTags: tagTypes,
    }),
    // New endpoint for fetching active subscriptions
    getActiveSubscriptions: builder.query<
      { success: boolean; activeSubscriptions: any[] },
      void
    >({
      query: () => ({
        url: API_ROUTES.STRIPE.ACTIVE_SUBSCRIPTIONS, // Update with your active subscriptions API route
        method: 'GET',
      }),
      providesTags: tagTypes,
    }),
    // New endpoint for canceling a subscription at the end of the period
    cancelSubscriptionAtPeriodEnd: builder.mutation<
      void,
      { subscriptionId: string }
    >({
      query: ({ subscriptionId }) => ({
        url: API_ROUTES.STRIPE.CANCEL_SUBSCRIPTION, // Update with your cancel subscription API route
        method: 'POST',
        body: { subscriptionId },
      }),
      invalidatesTags: tagTypes,
    }),
    // Get subscription details endpoint
    getSubscriptionDetails: builder.query<SubscriptionDetails, void>({
      query: () => ({
        url: '/stripe/subscriptions/details',
        method: 'GET',
      }),
      providesTags: tagTypes,
    }),

    // Cancel subscription endpoint
    cancelSubscription: builder.mutation<void, void>({
      query: () => ({
        url: '/stripe/subscriptions/cancel',
        method: 'POST',
      }),
      invalidatesTags: tagTypes,
    }),

    // Reactivate subscription endpoint
    reactivateSubscription: builder.mutation<void, void>({
      query: () => ({
        url: '/stripe/subscriptions/reactivate',
        method: 'POST',
      }),
      invalidatesTags: tagTypes,
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetUserQuery,
  useRegisterMutation,
  useCreateCardMutation,
  useGetCardsQuery,
  useCancelSubscriptionMutation,
  useGetSubscriptionDetailsQuery,
  useDeleteCardMutation,
  useCreateSubscriptionMutation,
  useGetActiveSubscriptionsQuery,
  useReactivateSubscriptionMutation,
  useCancelSubscriptionAtPeriodEndMutation,
} = authApi;
