import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import LoginForm from './components/LoginForm';
import ReceptionistTabs from './components/receptionists/ReceptionistTabs';
import NotFound from './components/not-found/NotFound';
import NavigationBar from './components/NavigationBar';
import ViewReceptionists from './components/receptionists/ViewReceptionists';
import EditReceptionistPage from './components/receptionists/EditReceptionistPage';
import ProtectedRoute from './components/ProtectedRoute';
import Profile from './components/profile/Profile';
import EditReceptionistIntent from './components/intents/EditReceptionistIntent';
import DisplayAllTelephonesPage from './components/telephones/DisplayAllTelephonesPage';
import ViewReceptionistFlows from './components/flow/ViewReceptionistFlows';
import EditReceptionistFlow from './components/flow/EditReceptionistFlow';
import { CreateReceptionistFlow } from './components/flow/CreateReceptionstFlow';
import ViewAllTransitionRouteGroups from './components/transition-route-groups/ViewAllTransitionRouteGroups';
import ViewTransitionRouteGroup from './components/transition-route-groups/ViewTransitionRouteGroup';
import CreateTransitionRouteGroup from './components/transition-route-groups/CreateTransitionRouteGroup';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import CreateFlowTransitionRoute from './components/flow-transition-routes/CreateFlowTransitionRoute';
import ViewFlowTransitionRoute from './components/flow-transition-routes/ViewFlowTransitionRoute';
import ViewFlowPage from './components/pages/ViewFlowPage';
import SignUpForm from './components/SignUpForm';
import CreateReceptionist from './components/receptionists/AddReceptionistPage';
import TestReceptionist from './components/receptionists/TestReceptionist';

function App() {
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const authState = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    setLoggedIn(Boolean(authState.user));
  }, [authState.user]);

  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  return (
    <Box>
      {loggedIn && (
        <NavigationBar
          isDrawerOpen={sideBarOpen}
          toggleDrawer={toggleSideBar}
        />
      )}
      <Routes>
        {/* Unprotected Route */}
        <Route path="/login" element={<LoginForm />} />
        <Route path={'/register'} element={<SignUpForm />} />

        {/* Protected Routes */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <Routes>
                <Route path="/*" element={<ReceptionistTabs />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/receptionists/*" element={<ReceptionistTabs />}>
                  <Route path="create" element={<CreateReceptionist />} />
                  <Route path="mine" element={<ViewReceptionists />} />
                </Route>
                <Route
                  path="/intents/edit/:id"
                  element={<EditReceptionistIntent />}
                />
                <Route path="/edit/:id" element={<EditReceptionistPage />} />
                <Route path="/test/:id" element={<TestReceptionist />} />

                <Route
                  path="/telephones/add"
                  element={<DisplayAllTelephonesPage />}
                />
                <Route path="/flows/*">
                  <Route
                    path="view/:agentId"
                    element={<ViewReceptionistFlows />}
                  />
                  <Route
                    path="edit/:agentId"
                    element={<EditReceptionistFlow />}
                  />
                  <Route
                    path="create/:agentId"
                    element={<CreateReceptionistFlow />}
                  />
                  <Route path={'pages/*'}>
                    <Route path={'create'} element={<ViewFlowPage />} />
                    <Route path={'view/:pageId'} element={<ViewFlowPage />} />
                  </Route>
                </Route>
                <Route path="/route-groups/*">
                  <Route
                    path="view/:agentId"
                    element={<ViewAllTransitionRouteGroups />}
                  />
                  <Route
                    path="details/:groupId"
                    element={<ViewTransitionRouteGroup />}
                  />
                  <Route
                    path="create/:agentId"
                    element={<CreateTransitionRouteGroup />}
                  />
                </Route>
                <Route path={'/flow-transition-routes/*'}>
                  <Route
                    path={'create/:agentId'}
                    element={<CreateFlowTransitionRoute />}
                  />
                  <Route
                    path={'view/:agentId'}
                    element={<ViewFlowTransitionRoute />}
                  />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Box>
  );
}

export default App;
