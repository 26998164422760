import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutline';
import { pink, red } from '@mui/material/colors';
import { subDays, isAfter } from 'date-fns';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
  LineChart,
  XAxis,
  YAxis,
  Line,
} from 'recharts';
import {
  Call,
  ReleasePhoneNumberArgs,
  useFetchReceptionistQuery,
  useGetCallByIdMutation,
  useGetCallMetricsQuery,
  useReleasePhoneNumberMutation,
} from '../../store/receptionistSlice';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorWidget from '../error-helpers/ErrorWidget';
import LoadingPage from '../LoadingWidget';
import { isMobile } from 'react-device-detect';
import { format, startOfHour } from 'date-fns';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import { showErrorToast } from '../../helpers/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const COLORS = [
  '#4CAF50', // Green
  '#F44336', // Red
  '#9E9E9E', // Gray
  '#FF9800', // Orange
];
interface CallData {
  date: string;
  callCount: number;
}

// Pulsating Record Icon for In-progress calls
const PulsatingRecordIcon = () => (
  <Box
    sx={{
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: red[500],
      boxShadow: `0 0 10px ${red[500]}, 0 0 20px ${red[500]}`,
      animation: 'pulse 1.5s infinite',
      '@keyframes pulse': {
        '0%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(1.2)' },
        '100%': { transform: 'scale(1)' },
      },
    }}
  />
);

export default function EditReceptionistPage() {
  const { id: receptionistId } = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState<number>(1); // Track the current page
  const [calls, setCalls] = useState<Call[]>([]); // State to store calls
  const [totalCalls, setTotalCalls] = useState<number>(0); // Track total number of calls
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const callsPerPage = 50; // Number of calls to show per page
  const [releasePhoneNumber, { isLoading: releasePhoneNumberLoading }] =
    useReleasePhoneNumberMutation();
  const {
    data: receptionist,
    isLoading,
    error,
  } = useFetchReceptionistQuery({
    id: receptionistId ?? '',
    offset: (currentPage - 1) * callsPerPage,
  });

  // In your useEffect for setting call data:
  useEffect(() => {
    if (!isLoading && !error && receptionist) {
      setDisplayName(receptionist.displayName);
      setCalls(receptionist.calls);
      setTotalCalls(callMetrics?.totalCalls || 0);

      // Calculate the date 7 days ago
      const sevenDaysAgo = subDays(new Date(), 7);

      // Filter calls to only include those from the past 7 days
      const recentCalls = receptionist.calls.filter((call) =>
        isAfter(new Date(call.callStartedAt), sevenDaysAgo)
      );

      // Process data for the graph: Group calls per hour for recent calls
      const callMap = new Map<string, number>();

      recentCalls.forEach((call) => {
        const callHour = format(
          startOfHour(new Date(call.callStartedAt)),
          'yyyy-MM-dd HH:mm'
        );

        const cHour = callMap.get(callHour);
        if (cHour) {
          callMap.set(callHour, cHour + 1);
        } else {
          callMap.set(callHour, 1);
        }
      });

      const processedData: CallData[] = Array.from(callMap.entries()).map(
        ([date, callCount]) => ({
          date,
          callCount,
        })
      );

      setCallData(processedData);
    }
  }, [receptionist, isLoading, error]);

  const handleAddPhoneNumber = () => {
    navigate(`/telephones/add?receptionistId=${receptionistId}`);
  };

  const handleReleasePhoneNumber = async () => {
    try {
      if (!receptionist || !receptionist.telephone) {
        return;
      }
      const releasePhoneNumberArgs: ReleasePhoneNumberArgs = {
        agentId: receptionistId ?? '',
        phoneNumber: receptionist.telephone.phoneNumber,
      };
      await releasePhoneNumber(releasePhoneNumberArgs).unwrap();
      setDialogOpen(false);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
  };

  const { data: callMetrics, isLoading: metricsLoading } =
    useGetCallMetricsQuery(receptionistId ?? '');

  const [getCallConversation, { isLoading: callConversationLoading }] =
    useGetCallByIdMutation();
  const [displayName, setDisplayName] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCall, setSelectedCall] = useState<null | Call>(null);
  const [callData, setCallData] = useState<CallData[]>([]);

  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (!isLoading && !error && receptionist) {
      setDisplayName(receptionist.displayName);
      setCalls(receptionist.calls);
      setTotalCalls(callMetrics?.totalCalls || 0);

      // Process data for the graph: Group calls per hour
      const callMap = new Map<string, number>();

      receptionist.calls.forEach((call) => {
        const callHour = format(
          startOfHour(new Date(call.callStartedAt)),
          'yyyy-MM-dd HH:mm'
        );

        const cHour = callMap.get(callHour);
        if (cHour) {
          callMap.set(callHour, cHour + 1);
        } else {
          callMap.set(callHour, 1);
        }
      });

      const processedData: CallData[] = Array.from(callMap.entries()).map(
        ([date, callCount]) => ({
          date,
          callCount,
        })
      );

      setCallData(processedData);
    }
  }, [receptionist, isLoading, error, callMetrics]);

  const handleGetCallConversation = async (callId: string) => {
    try {
      const callData = await getCallConversation(callId).unwrap();
      setSelectedCall(callData);
      setOpenDialog(true);
    } catch (error) {
      console.error(
        `Error fetching conversation for call ID ${callId}:`,
        error
      );
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    //setSelectedCall(null);
  };

  // Handle going to the previous page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle going to the next page
  const handleNextPage = () => {
    if (currentPage * callsPerPage < totalCalls) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (!user) {
    return <LoadingPage />;
  }
  // Assume that "receptionist.isSubscribed" contains whether the user is subscribed
  const isSubscribed = user.hasActiveSubscription;

  if (!receptionistId) {
    return ErrorWidget({ error: 'No receptionist ID supplied' });
  }

  if (error) {
    return ErrorWidget({ error: error });
  }

  if (isLoading || metricsLoading) {
    return LoadingPage();
  }

  if (!receptionist) {
    return <Box>No receptionist was found for that ID.</Box>;
  }

  // Calculate total sentiments and prepare data for Pie Chart with percentages
  const totalSentiments =
    (callMetrics?.positiveSentiments || 0) +
    (callMetrics?.negativeSentiments || 0) +
    (callMetrics?.neutralSentiments || 0) +
    (callMetrics?.unhandledSentiments || 0);

  const sentimentData = [
    {
      name: 'Positive',
      value: callMetrics?.positiveSentiments || 0,
      percentage:
        totalSentiments > 0
          ? ((callMetrics?.positiveSentiments || 0) / totalSentiments) * 100
          : 0,
    },
    {
      name: 'Negative',
      value: callMetrics?.negativeSentiments || 0,
      percentage:
        totalSentiments > 0
          ? ((callMetrics?.negativeSentiments || 0) / totalSentiments) * 100
          : 0,
    },
    {
      name: 'Neutral',
      value: callMetrics?.neutralSentiments || 0,
      percentage:
        totalSentiments > 0
          ? ((callMetrics?.neutralSentiments || 0) / totalSentiments) * 100
          : 0,
    },
    {
      name: 'Unhandled',
      value: callMetrics?.unhandledSentiments || 0,
      percentage:
        totalSentiments > 0
          ? ((callMetrics?.unhandledSentiments || 0) / totalSentiments) * 100
          : 0,
    },
  ];

  return (
    <Box
      display={'flex'}
      width={'100%'}
      flexDirection={'column'}
      alignItems={'start'}
      padding="10px"
      sx={{
        backgroundColor: 'white',
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={1}
        width={'99%'}
        mt={4}
        marginBottom={'16px'}
      >
        <TextField
          fullWidth={true}
          disabled={true}
          value={displayName}
          label={'Receptionist Name'}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </Box>

      <Box mt={1} mb={1}>
        {receptionist.telephone ? (
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
            <Typography>{user.hospitalName ?? 'N/A'} </Typography>
            <Typography>
              My Virtual Number: {receptionist.telephone.friendlyName}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography>
              No phone number associated with this receptionist.
            </Typography>
            <Button variant={'contained'} onClick={handleAddPhoneNumber}>
              Add a phone number
            </Button>
          </Box>
        )}
      </Box>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Edit Receptionist Phone Number</DialogTitle>
        <DialogContent>
          <Typography>
            To change this receptionists phone number, you must release the
            current phone number. Continue?
          </Typography>

          {releasePhoneNumberLoading && <LoadingPage />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleReleasePhoneNumber} color="primary">
            Release
          </Button>
        </DialogActions>
      </Dialog>

      {/* Display Call Metrics */}
      <>
        <Box
          mt={1}
          width="100%"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent={'center'}
          gap={4}
        >
          {/* Card for Call Volume by Hour Chart */}
          <Paper
            elevation={3}
            sx={{
              padding: '16px',
              width: isMobile ? '100%' : '33%',
              borderRadius: '8px',
            }}
          >
            <Typography variant="h6">Call Volume (Past 7 Days)</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={callData}>
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="callCount" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>

          {/* Card for Sentiments Distribution */}
          <Paper
            elevation={3}
            sx={{
              padding: '16px',
              width: isMobile ? '100%' : '30%',
              borderRadius: '8px',
            }}
          >
            <Typography variant="h6">Sentiments Distribution</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={sentimentData}
                  dataKey="value"
                  nameKey="name"
                  outerRadius="80%"
                  fill="#8884d8"
                >
                  {sentimentData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  layout="vertical"
                  align="right"
                  verticalAlign="middle"
                  formatter={(value, name) => {
                    const sentiment = sentimentData.find(
                      (s) => s.name === name.value
                    );
                    return `${sentiment?.name}, ${sentiment?.percentage.toFixed(2)}%`;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </Paper>

          {/* Card for Selected Call Details */}
          {selectedCall && (
            <Paper
              elevation={3}
              sx={{
                padding: '16px',
                width: isMobile ? '100%' : '30%',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="h6">Selected Call Details</Typography>

              {/* Caller's Phone Number */}
              <Typography>
                <strong>Caller’s Phone Number:</strong>{' '}
                {selectedCall.phoneNumber || 'Not available'}
              </Typography>

              {/* Call Status */}
              <Typography>
                <strong>Status:</strong>{' '}
                {selectedCall.callStatus || 'Not available'}
              </Typography>

              {/* Call Summary */}
              <Typography>
                <strong>Summary:</strong>{' '}
                {selectedCall.summary || 'No summary available'}
              </Typography>

              {/* Duration */}
              <Typography>
                <strong>Duration:</strong>{' '}
                {Math.floor(
                  (new Date(selectedCall.callLastAction).getTime() -
                    new Date(selectedCall.createdAt).getTime()) /
                    60000
                )}{' '}
                min{' '}
                {Math.floor(
                  ((new Date(selectedCall.callLastAction).getTime() -
                    new Date(selectedCall.createdAt).getTime()) %
                    60000) /
                    1000
                )}{' '}
                sec
              </Typography>

              {/* Call Outcome */}
              <Typography>
                <strong>Outcome:</strong>{' '}
                {selectedCall.callOutcome || 'Not available'}
              </Typography>

              {/* Sentiment */}
              <Typography>
                <strong>Sentiment:</strong>{' '}
                {selectedCall.sentiment || 'Not available'}
              </Typography>

              {/* Call Timestamp */}
              <Typography>
                <strong>Timestamp:</strong>{' '}
                {new Date(selectedCall.callStartedAt).toLocaleString()}
              </Typography>
            </Paper>
          )}
        </Box>
      </>

      <Box width={'100%'} mt={2} position="relative">
        <Paper
          elevation={0}
          sx={{
            padding: 2,
            position: 'relative',
          }}
        >
          <Typography variant="h6">Call History</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell>Outcome</TableCell>
                  <TableCell>Sentiment</TableCell>
                  <TableCell>View Transcript</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  filter: !isSubscribed ? 'blur(4px)' : 'none', // Apply blur if not subscribed
                }}
              >
                {calls.map((call, index) => {
                  const duration =
                    new Date(call.callLastAction).getTime() -
                    new Date(call.createdAt).getTime();
                  const formattedDuration = `${Math.floor(duration / 60000)} min ${Math.floor((duration % 60000) / 1000)} sec`;

                  return (
                    <TableRow
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedCall(call);
                      }}
                      key={call.id}
                      sx={{
                        bgcolor: index % 2 === 0 ? 'grey.100' : 'white',
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell>
                        {call.callStatus === 'done' ? (
                          <Box display="flex" alignItems="center">
                            <DoneIcon color="primary" />
                            <Typography ml={1}>Completed!</Typography>
                          </Box>
                        ) : (
                          <Box display="flex" alignItems="center">
                            <PulsatingRecordIcon />
                            <Typography ml={1}>Call In Progress...</Typography>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>{call.phoneNumber}</TableCell>
                      <TableCell>{call.summary || 'Not Available'}</TableCell>
                      <TableCell>{formattedDuration}</TableCell>
                      <TableCell>
                        {call.callOutcome || 'Not Available'}
                      </TableCell>
                      <TableCell>{call.sentiment || 'Not Available'}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleGetCallConversation(call.id)}
                          disabled={callConversationLoading}
                        >
                          {callConversationLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <ChatBubbleOutline />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Subscription Overlay */}
        {!isSubscribed && (
          <Box
            position="absolute"
            top={125}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            alignItems="start"
            justifyContent="center"
            bgcolor="rgba(255, 255, 255, 0.7)"
          >
            <Box
              p={2}
              onClick={() => {
                navigate('/profile');
              }}
              sx={{
                backgroundColor: pink[100],
                border: `1px solid ${red[400]}`,
                cursor: 'pointer',
              }}
            >
              <Typography color="error" variant="h6">
                Subscribe now for $19.99/month to access your recordings!
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          variant="contained"
        >
          Previous
        </Button>
        <Box mx={2}>
          <Typography>{`Page ${currentPage}`}</Typography>
        </Box>
        <Button
          onClick={handleNextPage}
          disabled={currentPage * callsPerPage >= totalCalls}
          variant="contained"
        >
          Next
        </Button>
      </Box>

      {/* Dialog to show conversation */}
      {selectedCall && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Call Conversation</DialogTitle>
          <DialogContent>
            {selectedCall.conversation?.length ? (
              <Box display="flex" flexDirection="column" gap={2}>
                {selectedCall.conversation.map((message) => (
                  <Box
                    key={message.id}
                    alignSelf={
                      message.sender === 'USER' ? 'flex-end' : 'flex-start'
                    }
                    component={Paper}
                    padding={2}
                    maxWidth="75%"
                    bgcolor={
                      message.sender === 'USER' ? 'primary.light' : 'grey.300'
                    }
                    color={message.sender === 'USER' ? 'white' : 'black'}
                    borderRadius={2}
                  >
                    <Typography
                      variant="body1"
                      color={message.sender === 'USER' ? 'white' : 'black'}
                    >
                      <strong>
                        {message.sender === 'AGENT' ? 'Darwin:' : ''}
                      </strong>{' '}
                      {message.message}
                    </Typography>
                    <Typography variant="caption">
                      {new Date(message.createdAt).toLocaleString()}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography>No conversation available.</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
