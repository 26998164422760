import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle, ChatBubble } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { setLoggedOut } from '../store/authSlice';
import { getErrorMessage } from './error-helpers/errorHelpers';
import { useLogoutMutation } from '../store/authApi';

type NavBarProps = {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
};
export default function NavigationBar({
  isDrawerOpen,
  toggleDrawer,
}: NavBarProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const [logoutMutation] = useLogoutMutation();
  const user = useSelector((state: RootState) => state.auth.user);

  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.auth.user);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogoutButtonClicked = async () => {
    try {
      await logoutMutation().unwrap();
      dispatch(setLoggedOut());
    } catch (e) {
      console.error(getErrorMessage(e));
    } finally {
      navigate('/login');
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Darwin AI (
          {currentUser
            ? `Logged in as ${currentUser.firstName} ${currentUser.lastName}`
            : 'Not Logged in '}
          )
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="account"
          onClick={handleMenuOpen}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);

              if (currentUser != null) {
                navigate('/profile');
              } else {
                navigate('/login');
              }
            }}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            My Account
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Are you sure you want to log out?</DialogTitle>
        <DialogActions>
          <Button variant={'contained'} onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              onLogoutButtonClicked();
              setDialogOpen(false);
            }}
          >
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
        <div
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
          style={{ width: 250 }}
        >
          <IconButton onClick={toggleDrawer} style={{ marginLeft: 'auto' }}>
            <CloseIcon />
          </IconButton>
          <List>
            <ListItemButton component={Link} to="/receptionists/overview">
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="My Receptionists" />
            </ListItemButton>
            {user && user.agents && user.agents.length > 0 && (
              <ListItemButton
                component={Link}
                to={`/test/${user.agents[0].id}`}
              >
                <ListItemIcon>
                  <ChatBubble />
                </ListItemIcon>
                <ListItemText primary="Test/Chat with Receptionist" />
              </ListItemButton>
            )}
          </List>
        </div>
      </Drawer>
    </AppBar>
  );
}
