import { baseQuery } from '../api/api';
import { API_ROUTES } from '../config/apiRoutes';
import { createApi } from '@reduxjs/toolkit/query/react';
import { tagTypes } from './tagTypes';

export interface CallsMetrics {
  totalCalls: number;
  averageCallLength: number;
  neutralSentiments: number;
  positiveSentiments: number;
  negativeSentiments: number;
  unhandledSentiments: number;
}

export interface CallConversationMessage {
  id: string;
  callId: string;
  sender: string;
  message: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Call {
  id: string;
  callerId: string;
  phoneNumber: string;
  callStartedAt: string;
  callEndedAt: Date | null;
  callStatus: 'done' | 'in-progress' | 'ended';
  recordingUrl: string | null;
  callLastAction: Date;
  conversation?: CallConversationMessage[];
  summary: string | null; // Nullable field
  callOutcome: string | null; // Nullable field
  sentiment: string | null; // Nullable field
  createdAt: string;
}

export interface ReceptionistTelephone {
  id: string;
  phoneNumber: string;
  friendlyName: string;
  agentId: string;
  region: string;
  isoCountry: string;
}

export interface Intent {
  name: string; // The unique identifier of the intent (usually in the format: projects/<Project ID>/locations/<Location ID>/agents/<Agent ID>/intents/<Intent ID>)
  displayName: string; // The display name of the intent
  trainingPhrases: TrainingPhrase[];
  parameters?: Parameter[];
  priority?: number;
  description?: string; // A short description of the intent
  messages?: Message[]; // The messages that the agent responds with when this intent is matched
  isFallback?: boolean; // Indicates if the intent is a fallback intent
  agentId: string;
}

export interface TrainingPhrase {
  parts: TrainingPhrasePartText[];
  repeatCount: number;
  id: string;
}

export interface TrainingPhrasePartText {
  text: string;
  parameterId: string;
}

export interface Parameter {
  id: string; // The unique identifier of the parameter
  entityType: string; // The entity type of the parameter
  isList: boolean; // Indicates if the parameter is a list of values
  redaction?: boolean; // Indicates if the parameter should be redacted
}

export interface Message {
  text?: TextMessage; // The text message to send when this intent is matched
}

export interface TextMessage {
  text: string[]; // The text responses to send
}

interface ReceptionistIntentTrainingPhraseAttributes {
  id: number;
  phrase: string;
  agentIntentId: number;
}

interface ReceptionistIntentLabelAttributes {
  id: number;
  label: string;
  agentIntentId: number;
}

interface ReceptionistIntentAttributes {
  id: number;
  displayName: string;
  description: string;
  labels: ReceptionistIntentLabelAttributes[];
  trainingPhrases: ReceptionistIntentTrainingPhraseAttributes[];
}

export interface Receptionist {
  id: string;
  name: string;
  displayName: string;
  userId?: string;
  intents: ReceptionistIntentAttributes[];
  initialCallMessage: string;
  telephone: ReceptionistTelephone | null;
  calls: Call[];
}

interface ReservePhoneNumberArgs {
  agentId: string;
  phoneNumber: string;
}

export interface ReleasePhoneNumberArgs {
  agentId: string;
  phoneNumber: string;
}

export interface FetchReceptionistArgs {
  id: string;
  offset: number;
}

interface ConversationMessage {
  receptionistId: string;
  sender: string;
  message: string;
}

interface ChatRequest {
  conversationHistory: ConversationMessage[];
  latestMessage: string;
  id: string;
}

interface ChatResponse {
  response: string;
}

export const receptionistsApiService = createApi({
  reducerPath: 'receptionists',
  baseQuery: baseQuery,
  tagTypes: tagTypes,
  endpoints: (builder) => ({
    fetchReceptionists: builder.query<Receptionist[], void>({
      query: () => {
        return API_ROUTES.RECEPTIONISTS.GET;
      },
      providesTags: tagTypes,
    }),
    createReceptionist: builder.mutation<Receptionist, Partial<Receptionist>>({
      query: (newReceptionist) => ({
        url: API_ROUTES.RECEPTIONISTS.CREATE,
        method: 'POST',
        body: newReceptionist,
      }),
      invalidatesTags: tagTypes,
    }),
    fetchReceptionist: builder.query<Receptionist, FetchReceptionistArgs>({
      query: ({ id, offset }) => ({
        url: API_ROUTES.RECEPTIONISTS.GET_AGENT_BY_ID(id),
        method: 'GET',
        params: { offset },
      }),
      providesTags: tagTypes,
    }),
    updateReceptionist: builder.mutation<Receptionist, Partial<Receptionist>>({
      query: (receptionist) => ({
        url: API_ROUTES.RECEPTIONISTS.UPDATE,
        method: 'PATCH',
        body: receptionist,
      }),
      invalidatesTags: tagTypes,
    }),
    fetchReceptionistIntents: builder.query<Intent[], string>({
      query: (id: string) => {
        return API_ROUTES.RECEPTIONISTS.GET_INTENTS_BY_AGENT_ID(id);
      },
      providesTags: tagTypes,
    }),
    deleteReceptionist: builder.mutation<void, string>({
      query: (agentId: string) => ({
        url: API_ROUTES.RECEPTIONISTS.DELETE_RECEPTIONIST(agentId),
        method: 'DELETE',
      }),
      invalidatesTags: tagTypes,
    }),
    addIntent: builder.mutation<Intent, Partial<Intent>>({
      query: (newIntent) => ({
        url: API_ROUTES.RECEPTIONISTS.CREATE_INTENT,
        method: 'POST',
        body: newIntent,
      }),
      invalidatesTags: tagTypes,
    }),
    deleteIntent: builder.mutation<void, { agentId: string; intentId: string }>(
      {
        query: ({ agentId, intentId }) => ({
          url: API_ROUTES.RECEPTIONISTS.DELETE_INTENT(agentId, intentId),
          method: 'DELETE',
        }),
        invalidatesTags: tagTypes,
      }
    ),
    fetchIntent: builder.query<Intent, { agentId: string; intentId: string }>({
      query: ({ agentId, intentId }) => ({
        url: API_ROUTES.RECEPTIONISTS.GET_INTENT_DETAILS(agentId, intentId),
        method: 'GET',
      }),
      providesTags: tagTypes,
    }),
    updateIntent: builder.mutation<Intent, Partial<Intent>>({
      query: (intent: Partial<Intent>) => ({
        url: API_ROUTES.RECEPTIONISTS.UPDATE_INTENT,
        method: 'PATCH',
        body: intent,
      }),
      invalidatesTags: tagTypes,
    }),
    fetchAvailablePhoneNumbers: builder.query<ReceptionistTelephone[], void>({
      query: () => {
        return API_ROUTES.TELEPHONES.GET_TELEPHONES;
      },
      providesTags: tagTypes,
    }),
    reservePhoneNumber: builder.mutation<void, ReservePhoneNumberArgs>({
      query: (reserveBody) => ({
        url: API_ROUTES.TELEPHONES.RESERVE_PHONE_NUMBER,
        method: 'POST',
        body: reserveBody,
      }),
      invalidatesTags: tagTypes,
    }),
    releasePhoneNumber: builder.mutation<void, ReleasePhoneNumberArgs>({
      query: (releaseBody) => ({
        url: API_ROUTES.TELEPHONES.RELEASE_PHONE_NUMBER,
        method: 'POST',
        body: releaseBody,
      }),
      invalidatesTags: tagTypes,
    }),
    getCallById: builder.mutation<Call, string>({
      query: (callId) => ({
        url: API_ROUTES.RECEPTIONISTS.GET_CALL_BY_ID,
        body: { callId },
        method: 'POST',
      }),
    }),
    getCallMetrics: builder.query<CallsMetrics, string>({
      query: (agentId) => ({
        url: API_ROUTES.RECEPTIONISTS.GET_CALL_METRICS,
        method: 'POST',
        body: { agentId },
      }),
    }),
    createMessage: builder.mutation<ChatResponse, ChatRequest>({
      query: (body) => ({
        url: '/voice/message/create',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useFetchReceptionistsQuery,
  useCreateReceptionistMutation,
  useFetchReceptionistQuery,
  useFetchReceptionistIntentsQuery,
  useAddIntentMutation,
  useDeleteIntentMutation,
  useFetchIntentQuery,
  useUpdateIntentMutation,
  useFetchAvailablePhoneNumbersQuery,
  useReservePhoneNumberMutation,
  useReleasePhoneNumberMutation,
  useUpdateReceptionistMutation,
  useDeleteReceptionistMutation,
  useGetCallByIdMutation,
  useGetCallMetricsQuery,
  useCreateMessageMutation,
} = receptionistsApiService;
