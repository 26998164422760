import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store/store';
import { Navigate } from 'react-router-dom';
import { useGetUserQuery } from '../store/authApi';
import { setCurrentUser } from '../store/authSlice';
import LoadingPage from './LoadingWidget';

interface ProtectedRouteProps {
  children: React.ReactNode;
  redirectTo?: string;
}

/*
 * This component checks to make sure that the user is logged in. If not,
 * then it navigates to the /login page
 * */
export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: RootState) => state.auth);
  const { data, isLoading, error } = useGetUserQuery();

  useEffect(() => {
    if (!isLoading && !error && data) {
      dispatch(setCurrentUser(data));
    }
  }, [dispatch, authState.user, isLoading, error, data]);

  if (isLoading) {
    return LoadingPage();
  }

  if (error || !data) {
    return <Navigate to={'/login'} />;
  }

  return <>{children}</>;
}
