import React from 'react';
import { CircularProgress, Container, Typography } from '@mui/material';

const LoadingPage = () => {
  return (
    <Container style={{ textAlign: 'center', paddingTop: '50px' }}>
      <CircularProgress />
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Loading, please wait...
      </Typography>
    </Container>
  );
};

export default LoadingPage;
