import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Flow,
  useFetchFlowQuery,
  useUpdateFlowMutation,
} from '../../store/flowsApi';
import ErrorWidget from '../error-helpers/ErrorWidget';
import LoadingPage from '../LoadingWidget';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TransitionRoute } from '../../store/routeTransitionGroupApi';
import { showErrorToast, showSuccessToast } from '../../helpers/helpers';
import { getErrorMessage } from '../error-helpers/errorHelpers';
import ViewFlowPages from '../pages/ViewFlowPages';

export default function EditReceptionistFlow() {
  const { agentId } = useParams<string>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const flowId = searchParams.get('flowId');
  const fetchParams = {
    flowId: flowId ?? '',
    agentId: agentId ?? '',
  };
  const { data: flow, isLoading, error } = useFetchFlowQuery(fetchParams);
  const navigate = useNavigate();
  const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] =
    useState<boolean>(false);
  const [currentTransitionRouteGroup, setCurrentTransitionRouteGroup] =
    useState<TransitionRoute | null>(null);
  const [updateFlow, { isLoading: updatingFlow }] = useUpdateFlowMutation();

  if (error) {
    return ErrorWidget({ error });
  }

  if (isLoading) {
    return LoadingPage();
  }

  if (!flow) {
    return ErrorWidget({ error: 'No data to display' });
  }

  const onDeleteRouteGroupTransitionButtonClicked = async (
    transition: TransitionRoute
  ) => {
    try {
      if (!flow.transitionRoutes) {
        return;
      }

      const index: number | undefined = flow.transitionRoutes?.findIndex(
        (t) => t.name === transition.name
      );
      if (index === undefined) {
        return;
      }

      const updatedFlow: Flow = structuredClone(flow);
      if (!updatedFlow.transitionRoutes) {
        return;
      }

      updatedFlow.transitionRoutes.splice(index, 1);

      await updateFlow(updatedFlow).unwrap();
      showSuccessToast('Successfully deleted this route transition!');
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const {
    displayName,
    description,
    transitionRoutes,
    eventHandlers,
    nluSettings,
    fulfillment,
    transitionRouteGroups,
    knowledgeConnectorSettings,
    advancedSettings,
  } = flow;

  return (
    <Box p={3}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Edit Flow: {displayName}
          </Typography>
          {description && (
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {description}
            </Typography>
          )}

          {/* Displaying Transition Routes */}
          {transitionRoutes && transitionRoutes.length > 0 && (
            <Box mt={2}>
              <Typography variant="h6">Transition Routes:</Typography>
              <List>
                {transitionRoutes.map((route, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        onClick={() => {
                          setCurrentTransitionRouteGroup(route);
                          setDeleteGroupDialogOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <Box marginRight={'10px'}>
                      <IconButton
                        onClick={() => {
                          navigate(
                            `/flow-transition-routes/view/${agentId}?flowId=${flowId}&routeName=${route.name}`
                          );
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>

                    <ListItemText
                      primary={`Route ${route.description || 'No Description Specified'}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <Box>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              variant={'contained'}
              onClick={() => {
                navigate(
                  `/flow-transition-routes/create/${agentId}?flowId=${flowId}`
                );
              }}
            >
              Add Flow Route
            </Button>
          </Box>
          <Dialog
            open={deleteGroupDialogOpen}
            onClose={() => {
              setDeleteGroupDialogOpen(false);
              setCurrentTransitionRouteGroup(null);
            }}
          >
            <DialogTitle>
              Are you sure you want to delete the route{' '}
              {currentTransitionRouteGroup?.description ||
                currentTransitionRouteGroup?.name}
              ?
            </DialogTitle>
            <DialogContent>{updatingFlow && <LoadingPage />}</DialogContent>
            <DialogActions>
              <Button
                variant={'contained'}
                onClick={() => {
                  setDeleteGroupDialogOpen(false);
                  setCurrentTransitionRouteGroup(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant={'contained'}
                onClick={async () => {
                  if (currentTransitionRouteGroup) {
                    await onDeleteRouteGroupTransitionButtonClicked(
                      currentTransitionRouteGroup
                    );
                  }
                  setDeleteGroupDialogOpen(false);
                  setCurrentTransitionRouteGroup(null);
                }}
              >
                Delete Route
              </Button>
            </DialogActions>
          </Dialog>

          {/* Displaying Event Handlers */}
          {eventHandlers && eventHandlers.length > 0 && (
            <Box mt={2}>
              <Typography variant="h6">Event Handlers:</Typography>
              <List>
                {eventHandlers.map((handler, index) => (
                  <ListItem key={index}>
                    <Box marginRight={'10px'}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Box>
                    <ListItemText
                      primary={`Handler ${index + 1}`}
                      secondary={`Event: ${handler.event || 'N/A'}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {nluSettings && (
            <Box mt={2}>
              <Typography variant="h6">NLU Settings:</Typography>
              <Typography variant="body2">
                Model Type: {nluSettings.modelType || 'N/A'}
              </Typography>
            </Box>
          )}

          {/* Displaying Fulfillment */}
          {fulfillment && (
            <Box mt={2}>
              <Typography variant="h6">Fulfillment:</Typography>
              <Typography variant="body2">
                {fulfillment?.messages
                  ? `Messages: ${fulfillment.messages.length}`
                  : 'No fulfillment messages'}
              </Typography>
            </Box>
          )}

          {transitionRouteGroups && transitionRouteGroups.length > 0 && (
            <Box mt={2}>
              <Typography variant="h6">Transition Route Groups:</Typography>
              <List>
                {transitionRouteGroups.map((group, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={`Group ${index + 1}: ${group}`} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {knowledgeConnectorSettings && (
            <Box mt={2}>
              <Typography variant="h6">
                Knowledge Connector Settings:
              </Typography>
              <Typography variant="body2">
                {JSON.stringify(knowledgeConnectorSettings, null, 2)}
              </Typography>
            </Box>
          )}

          {advancedSettings && (
            <Box mt={2}>
              <Typography variant="h6">Advanced Settings:</Typography>
              <Typography variant="body2">
                {JSON.stringify(advancedSettings, null, 2)}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      <ViewFlowPages />
    </Box>
  );
}
