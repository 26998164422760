import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { getErrorMessage } from './errorHelpers';

interface ErrorPageProps {
  error: SerializedError | FetchBaseQueryError | string | null;
  onRetry?: () => void;
}

export default function ErrorWidget({ error, onRetry }: ErrorPageProps) {
  const errorMessage = getErrorMessage(error);

  return (
    <Container style={{ textAlign: 'center', paddingTop: '50px' }}>
      <Typography variant="h6" color="error">
        {errorMessage}
      </Typography>
      {onRetry && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
          onClick={onRetry}
        >
          Retry
        </Button>
      )}
    </Container>
  );
}
