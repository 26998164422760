import React from 'react';
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetPageQuery } from '../../store/pagesApi';
import ErrorWidget from '../error-helpers/ErrorWidget';
import LoadingPage from '../LoadingWidget';

export default function ViewFlowPage() {
  const { pageId } = useParams();
  const [urlSearchParams] = useSearchParams();
  const agentId = urlSearchParams.get('agentId');
  const flowId = urlSearchParams.get('flowId');

  const {
    data: page,
    isLoading,
    error,
  } = useGetPageQuery({
    agentId: agentId ?? '',
    flowId: flowId ?? '',
    pageId: pageId ?? '',
  });

  if (error) {
    return <ErrorWidget error={error} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!page) {
    return <Box>No data available for this page.</Box>;
  }

  return (
    <Box padding={2}>
      <Typography variant="h4" gutterBottom>
        {page.displayName}
      </Typography>

      {page.entryFulfillment && (
        <Box marginY={2}>
          <Typography variant="h6">Entry Fulfillment</Typography>
          <List>
            {page.entryFulfillment.messages.map((message, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={message.text?.text.join(', ') || 'No text available'}
                  secondary={
                    message.payload ? JSON.stringify(message.payload) : null
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {page.transitionRoutes && (
        <Box marginY={2}>
          <Typography variant="h6">Transition Routes</Typography>
          <List>
            {page.transitionRoutes.map((route, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Intent: ${route.intent || 'None'}, Condition: ${route.condition || 'None'}`}
                  secondary={`Target Page: ${route.targetPage || 'None'}, Target Flow: ${route.targetFlow || 'None'}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {page.eventHandlers && (
        <Box marginY={2}>
          <Typography variant="h6">Event Handlers</Typography>
          <List>
            {page.eventHandlers.map((handler, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Event: ${handler.event}`}
                  secondary={`Target Page: ${handler.targetPage || 'None'}, Target Flow: ${handler.targetFlow || 'None'}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Divider />
    </Box>
  );
}
