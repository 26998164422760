import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      padding={'25px'}
      alignItems={'center'}
    >
      <Typography variant={'h5'}>Oops... Not found</Typography>
      <Box marginTop={'25px'}>
        <Button variant={'contained'} onClick={() => navigate('/')}>
          <Typography>Back to Home</Typography>
        </Button>
      </Box>
    </Box>
  );
}
